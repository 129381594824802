import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import noWinImg from "../../../assets/no-win-image.svg";
import SratchCardModel from "../components/scratch-card-model";
import MultipleVochers from "../components/multipleVouchers";
import Wheel from "../components/wheel";
import rewardsClientX from "../../../utils/api/rewardClientX";
import InlineLoader from "../../../components/InlineLoader";
import { CoverPageSkeleton } from "../../../components/skeleton";
import WonCardTitle from "../components/wonCardTitle";
import { connect } from "react-redux";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import classNames from "classnames";
import Dots from "../../../assets//billpay/3-dots.png"
import { Modal } from "react-bootstrap";
import ic_spinwheel from "../../../assets/rewards/ic_spin_wheel.png"
import ic_scratchcard from "../../../assets/rewards/ic_scratch_card.png"
import ic_spinwheel_loader from "../../../assets/rewards/ic_spin_wheel.gif"
import ic_scratchcard_loader from "../../../assets/rewards/ic_scratch_card.gif"
import ic_rupee_symbol from "../../../assets/billpay/ic_rupee_symbol.svg";
import ic_wrp_symbol from "../../../assets/rewards/ic_wrp_coin.png"

class Rewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rewards: [],
      offers: [],
      isRewardModelOpen: false,
      isModelTransitionDone: false,
      isWheelModelOpen: false,
      currentIndex: 0,
      isRewardFetching: false,
      isFinished: false,
      isFetchingRewards: false,
      isFetchingOffers: false,
      isLastPage: false,
      pageNo: 1,
      activatedCard: null,
      showDropdown: false,
      showModal: false,
      cardType: "",
      rewardsBulkInfo: null,
      rewardsBulkData: null,
      rewardsBulkDataLoading: false
    };

    this.loadRef = React.createRef();
  }

  handleClose = () => {
    this.setState({ showModal: false, rewardsBulkData: null })
    this.initRewardsInfo()
    this.initRewards()
  }
  handleShow = (type) => this.setState({ showModal: true, cardType: type });

  handleClick = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  };

  componentDidMount() {
    if (this.props.profile === null) {
      this.props.history.push('/login')
    }
    this.initRewards();
    this.initRewardsInfo()
    this.setState(
      {
        isFetchingOffers: true,
      },
      () => {
        this.getOffers();
      }
    );

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && !this.state.isLastPage) {
          this.setState(
            (prevState) => ({ ...prevState, pageNo: prevState.pageNo + 1 }),
            () => {
              this.state.rewards.length > 0 &&
                this.getUserRewards(this.state.pageNo);
            }
          );
        }
      });
    });
    this.observer.observe(this.loadRef.current);
  }

  initRewards = () => {
    this.setState(
      {
        isFetchingRewards: true,
        activatedCard: null,
        pageNo: 1,
      },
      () => {
        this.getUserRewards(1);
      }
    );
  };

  initRewardsInfo = () => {
    rewardsClientX.get("/v1/customer/pending-cards").then((response) => {
      this.setState({
        rewardsBulkInfo: response.data.data
      })
    });
  };

  activateBulkRewards = (rewardType) => {
    let payload = {
      "reward_type": rewardType
    }
    this.setState({ rewardsBulkDataLoading: true })
    rewardsClientX.post("/v1/customer/cards/activate/bulk", payload)
      .then((response) => {
        //render entire animation and then display data
        if (rewardType === "scratch_card") {
          setTimeout(() => {
            this.setState({
              rewardsBulkData: response.data.data,
              rewardsBulkDataLoading: false
            })
          }, 2500);
        } else {
          setTimeout(() => {
            this.setState({
              rewardsBulkData: response.data.data,
              rewardsBulkDataLoading: false
            })
          }, 4500);
        }
      })
  };

  getOffers = () => {
    rewardsClientX.get("/v1/customer/offers").then((response) => {
      this.setState({
        offers: response.data.data,
        isFetchingOffers: false,
      });
    });
  };

  finished = (item) => {
    this.setState({
      isFinished: true,
    });
    this.activateCard(item);
  };

  getUserRewards = (pageNo) => {
    rewardsClientX
      .get("/v1/customer/cards", {
        params: {
          page: pageNo,
        },
      })
      .then((response) => {
        let isLast;
        isLast = response.data.links.next === null;
        if (pageNo === 1) {
          this.setState(
            (prevState) => ({
              ...prevState,
              rewards: response.data.data,
              isFetchingRewards: false,
              isLastPage: isLast,
            }),
            () => { }
          );
        } else {
          this.setState(
            (prevState) => ({
              ...prevState,
              rewards: prevState.rewards.concat(response.data.data),
              isFetchingRewards: false,
              isLastPage: isLast,
            }),
            () => { }
          );
        }

      });
  };

  openRewardModel = (index) => {
    this.setState({
      isRewardModelOpen: true,
      currentIndex: index,
    });
  };

  closeRewardModelHandler = (isActivated, item) => {
    this.setState(
      {
        isRewardModelOpen: false,
        isModelTransitionDone: false,
      },
      () => {
        isActivated && this.initRewards();
        this.initRewardsInfo()
      }
    );
  };

  openWheelModel = (index) => {
    this.setState({
      isWheelModelOpen: true,
      currentIndex: index,
    });
  };

  closeWheelModelHandler = (isActivated, isClaimed) => {
    this.setState(
      {
        isWheelModelOpen: false,
      },
      () => {
        if (isClaimed) {
          this.props.history.push(
            `/rewards/physical-prizes/${this.state.activatedCard[0].id}`
          );
        } else {
          isActivated &&
            this.setState(
              (prevState) => ({
                rewards: [],
              }),
              () => {
                this.initRewards();
                this.initRewardsInfo()
              }
            );
        }
      }
    );
  };

  activateCard = (item) => {
    const id = item.id;
    rewardsClientX
      .post(`/v1/customer/cards/${id}/activate`, {})
      .then((response) => {
        this.setState({
          activatedCard: response.data.data,
        });
      });
  };

  getProfile = (card) => {
    const { email_verified, mobile_verified } = this.props.profile;
    if (email_verified && mobile_verified) {
      this.props.history.push(`/rewards/vouchers/${card.id}`);
    } else {
      this.props.history.push(`/verification-info/${card.id}`);
    }
    //   });
  };

  wonCardDetail = (card) => {
    const type = card.type;
    switch (type) {
      case "voucher":
        this.props.history.push(`/verification-info/${card.id}`);
        break;
      case "item":
        this.props.history.push(
          `/rewards/physical-prizes/${card.id}&${card.claim_id ? card.claim_id : ""
          }`
        );
        break;

      default:
        break;
    }
  };

  AllSpinWheelsModalComponent = () => {
    return (
      <>
        <span className="font-weight-bold h5 w-100" style={{ color: "#656565", letterSpacing: "2px" }}>WHEEL OF</span>
        <span className="font-weight-bold h2 w-100" style={{ color: "#D9A419", letterSpacing: "2px" }}>FORTUNE</span>
        {!this.state.rewardsBulkData ?
          (
            <>
              <span className="font-weight-bold h5 w-100 mt-md-3 mt-2" style={{ color: "#656565" }}>You have {this.state.rewardsBulkInfo?.spin_wheels} Spin Wheels</span>
              <hr className="w-100" />
              <span className="font-weight-bold h5 w-100" style={{ color: "#00BEAA" }}>Currently Spinning</span>
              <span className="font-weight-bold h1 w-100" style={{ color: "#00BEAA" }}>{this.state.rewardsBulkInfo?.allowed_spin_wheels}</span>
              {
                this.state.rewardsBulkDataLoading ?
                  <img className="w-100 mb-2" src={ic_spinwheel_loader} alt="spin wheel" title="spin wheel" /> :
                  <img className="w-100 mb-2" src={ic_spinwheel} alt="spin wheel" title="spin wheel" />
              }
              <p style={{ color: "#333" }}>Note: Only {this.state.rewardsBulkInfo?.bulk_process_card_count || '-'} Spin Wheels at one attempt</p>
              <div className="d-flex flex-row justify-content-between w-100 mt-3">
                <button variant="primary"
                  disabled={this.state.rewardsBulkDataLoading}
                  onClick={() => this.activateBulkRewards('spin_wheel')}
                  className={`animated-btn m-2 ${this.state.rewardsBulkDataLoading ? "animated-btn-disabled" : ""}`}
                >Spin All</button>
              </div>
            </>
          ) :
          (
            <>
              <span className="font-weight-bold h2 w-100 mt-md-3 mt-2" style={{ color: "#00BEAA" }}>Congratulations!</span>
              <p style={{ color: "#333" }}>Your winnings from {this.state.rewardsBulkData?.total_cards_processed} {this.state.rewardsBulkData?.total_cards_processed === 1 ? "Spin Wheel" : "Spin Wheels"}</p>
              <div className="d-flex flex-column justify-content-center  w-100 mt-3">
                {this.state.rewardsBulkData?.cash_prize?.value > 0 &&
                  <div className="d-flex flex-column align-items-center">
                    <span className="h5" style={{ color: "#6372FF" }}>Cash Won from {this.state.rewardsBulkData?.cash_prize?.value} {this.state.rewardsBulkData?.cash_prize?.value === 1 ? "Spin Wheel" : "Spin Wheels"}</span>
                    <span className="h4 d-flex align-items-center font-weight-bold"><img className=" m-0 mr-2" width="22px" src={ic_rupee_symbol} alt="rupee" title="rupee" />{this.state.rewardsBulkData?.cash_prize?.count}</span>
                    <hr className="w-100" />
                  </div>
                }
                {this.state.rewardsBulkData?.voucher_prize?.value > 0 &&
                  <div className="d-flex flex-column align-items-center">
                    <span className="h5" style={{ color: "#6372FF" }}>Vouchers Won from {this.state.rewardsBulkData?.voucher_prize?.value} {this.state.rewardsBulkData?.voucher_prize?.value === 1 ? "Spin Wheel" : "Spin Wheels"}</span>
                    <span className="h4 d-flex align-items-center font-weight-bold"><img className=" m-0 mr-2" width="22px" src={ic_rupee_symbol} alt="rupee" title="rupee" />{this.state.rewardsBulkData?.voucher_prize?.count}</span>
                    <hr className="w-100" />
                  </div>
                }
                {this.state.rewardsBulkData?.wrp_prize?.value > 0 &&
                  <div className="d-flex flex-column align-items-center">
                    <span className="h5" style={{ color: "#6372FF" }}>WINDS Coins Won from {this.state.rewardsBulkData?.wrp_prize?.value} {this.state.rewardsBulkData?.wrp_prize?.value === 1 ? "Spin Wheel" : "Spin Wheels"}</span>
                    <span className="h4 d-flex align-items-center font-weight-bold"><img className=" m-0 mr-2" width="22px" src={ic_wrp_symbol} alt="rupee" title="rupee" />{this.state.rewardsBulkData?.wrp_prize?.count}</span>
                    <hr className="w-100" />
                  </div>
                }
              </div>
              <button variant="primary" onClick={() => this.handleClose()} className="animated-btn m-2">
                <FormattedMessage id="general.ok" defaultMessage="OK" />
              </button>
            </>
          )
        }
      </>
    )
  }

  AllScratchCardsModalComponent = () => {
    return (
      <>
        <span className="font-weight-bold h2 w-100" style={{ color: "#D9A419", letterSpacing: "2px" }}>SCRATCH</span>
        <span className="font-weight-bold h5 w-100" style={{ color: "#656565", letterSpacing: "2px" }}>CARDS</span>
        {!this.state.rewardsBulkData ?
          (
            <>
              <span className="font-weight-bold h5 w-100 mt-md-3 mt-2" style={{ color: "#656565" }}>You have {this.state.rewardsBulkInfo?.scratch_cards} Scratch Cards</span>
              <hr className="w-100" />
              <span className="font-weight-bold h5 w-100" style={{ color: "#00BEAA" }}>Currently Scratching</span>
              <span className="font-weight-bold h1 w-100" style={{ color: "#00BEAA" }}>{this.state.rewardsBulkInfo?.allowed_scratch_cards}</span>
              {
                this.state.rewardsBulkDataLoading ?
                  <img className="w-100 mb-2" src={ic_scratchcard_loader} alt="scratch card" title="scratch card" /> :
                  <img className="w-100 mb-2" src={ic_scratchcard} alt="scratch card" title="scratch card" />
              }
              <p style={{ color: "#333" }}>Note: Only {this.state.rewardsBulkInfo?.bulk_process_card_count || '-'} Scratch Cards at one attempt</p>
              <div className="d-flex flex-row justify-content-between w-100 mt-3">
                <button variant="primary"
                  disabled={this.state.rewardsBulkDataLoading}
                  onClick={() => this.activateBulkRewards('scratch_card')}
                  className={`animated-btn m-2 ${this.state.rewardsBulkDataLoading ? "animated-btn-disabled" : ""}`}
                >Scratch all Cards</button>
              </div>
            </>
          ) :
          (
            <>
              <span className="font-weight-bold h2 w-100 mt-md-3 mt-2" style={{ color: "#00BEAA" }}>Congratulations!</span>
              <p style={{ color: "#333" }}>Your winnings from {this.state.rewardsBulkData?.total_cards_processed} {this.state.rewardsBulkData?.total_cards_processed === 1 ? "card" : "cards"} Scratched</p>
              <div className="d-flex flex-column justify-content-center  w-100 mt-3">
                {this.state.rewardsBulkData?.cash_prize?.value > 0 &&
                  <div className="d-flex flex-column align-items-center">
                    <span className="h5" style={{ color: "#6372FF" }}>Cash Won from {this.state.rewardsBulkData?.cash_prize?.value} {this.state.rewardsBulkData?.cash_prize?.value === 1 ? "Card" : "Cards"}</span>
                    <span className="h4 d-flex align-items-center font-weight-bold"><img className=" m-0 mr-2" width="22px" src={ic_rupee_symbol} alt="rupee" title="rupee" />{this.state.rewardsBulkData?.cash_prize?.count}</span>
                    <hr className="w-100" />
                  </div>
                }
                {this.state.rewardsBulkData?.voucher_prize?.value > 0 &&
                  <div className="d-flex flex-column align-items-center">
                    <span className="h5" style={{ color: "#6372FF" }}>Vouchers Won from {this.state.rewardsBulkData?.voucher_prize?.value} {this.state.rewardsBulkData?.voucher_prize?.value === 1 ? "Card" : "Cards"}</span>
                    <span className="h4 d-flex align-items-center font-weight-bold"><img className=" m-0 mr-2" width="22px" src={ic_rupee_symbol} alt="rupee" title="rupee" />{this.state.rewardsBulkData?.voucher_prize?.count}</span>
                    <hr className="w-100" />
                  </div>
                }
                {this.state.rewardsBulkData?.wrp_prize?.value > 0 &&
                  <div className="d-flex flex-column align-items-center">
                    <span className="h5" style={{ color: "#6372FF" }}>WINDS Coins Won from {this.state.rewardsBulkData?.wrp_prize?.value} {this.state.rewardsBulkData?.wrp_prize?.value === 1 ? "Card" : "Cards"}</span>
                    <span className="h4 d-flex align-items-center font-weight-bold"><img className=" m-0 mr-2" width="22px" src={ic_wrp_symbol} alt="rupee" title="rupee" />{this.state.rewardsBulkData?.wrp_prize?.count}</span>
                    <hr className="w-100" />
                  </div>
                }
                {this.state.rewardsBulkData?.no_prize > 0 &&
                  <div className="d-flex flex-column align-items-center mb-3">
                    <span className="h5" style={{ color: "#6372FF" }}>No Prize from {this.state.rewardsBulkData?.no_prize} {this.state.rewardsBulkData?.no_prize === 1 ? "Card" : "Cards"}</span>
                  </div>
                }
              </div>
              <button variant="primary" onClick={() => this.handleClose()} className="animated-btn m-2">
                <FormattedMessage id="general.ok" defaultMessage="OK" />
              </button>
            </>
          )
        }
      </>
    )
  }

  modelEntered = () => {
    this.setState({ isModelTransitionDone: true });
  };

  render() {
    return (
      <div className="container font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="navbar.rewards"
                      defaultMessage="Cahback-Rewards"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-8">
            <h2 className="font-proxima-nova-bold d-inline-block">
              <FormattedMessage
                id="rewards.your-rewards"
                defaultMessage="Your Cashback/Rewards"
              />
            </h2>
          </div>
          <div className="col-md-4 col-12 text-right d-flex justify-content-end align-items-center">
            <a
              href="#offers"
              className="btn btn-outline d-inline-block"
              style={{ color: "#087CD8" }}
            >
              <FormattedMessage id="rewards.check-offers" defaultMessage="Check Offers" />
            </a>
            <li className="nav-item text-right cursor-pointer" onClick={this.handleClick} style={{ listStyle: "none" }}>
              <img src={Dots} alt="3 dots" title="3 dots" width="30px" style={{ rotate: "90deg" }} />
              <div
                className={classNames("dropdown-menu", "dropdown-menu-right", {
                  show: this.state.showDropdown,
                })}
                onMouseLeave={() => {
                  this.setState({ showDropdown: false });
                }}
              >
                {this.state.rewardsBulkInfo?.scratch_cards > 1 &&
                  <div className="dropdown-item dropdown-icons" onClick={() => this.handleShow("scratch_cards")}>
                    Scratch all Cards
                  </div>
                }
                {this.state.rewardsBulkInfo?.spin_wheels > 1 &&
                  <div className="dropdown-item dropdown-icons" onClick={() => this.handleShow("spin_wheels")}>
                    Spin all Spinwheels
                  </div>
                }
                <Link className="dropdown-item dropdown-icons" to="/rewards-info">
                  <FormattedMessage id="general.info" defaultMessage="Rewards Info" />
                </Link>
              </div>
            </li>
            <Modal
              className="no-header-modal"
              show={this.state.showModal}
              onHide={this.handleClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body className="text-center d-flex flex-column p-4">
                {!this.state.rewardsBulkDataLoading &&
                  <button className="btn-close-card-type " disabled={this.state.rewardsBulkDataLoading} onClick={this.handleClose}>
                    <i className="fa fa-close"></i>
                  </button>
                }
                {this.state.cardType === "spin_wheels" ?
                  <this.AllSpinWheelsModalComponent /> :
                  <this.AllScratchCardsModalComponent />
                }
              </Modal.Body>
            </Modal>
          </div>
        </div>

        <div className="row mt-3 mb-5 font-proxima-nova">
          {this.state.isFetchingRewards ? (
            <div className="col-12">
              <CoverPageSkeleton rows={2} columns={4} height={65} width={65} />
            </div>
          ) : this.state.rewards.length === 0 ? (
            <div className="empty-state">
              <EmptyState
                title={
                  <FormattedMessage
                    id="rewards.no-rewards"
                    defaultMessage="No rewards yet. It's only matter of time!"
                  />
                }
                imageWidth={40}
                image={EmptyImage}
              />
            </div>
          ) : (
            this.state.rewards.map((item, index) => {
              return (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" key={index}>
                  <div className="rewards-card">
                    {item.card_type === "unused-card" &&
                      item.type === "scratch-card" && (
                        <React.Fragment>
                          <div
                            className="reward-icon"
                            onClick={() => this.openRewardModel(index)}
                          >
                            <img
                              src={item.image_url}
                              alt="rewards icon"
                              title="rewards icon"
                              className="d-block"
                            />
                            <h4 className="text-white position-absolute abs-center">
                              <FormattedMessage
                                id="rewards.tap-scratch"
                                defaultMessage="Tap to scratch"
                              />
                            </h4>
                          </div>
                          {this.state.isRewardModelOpen &&
                            this.state.currentIndex === index && (
                              <SratchCardModel
                                reward={item}
                                finished={() => this.finished(item)}
                                activatedCard={this.state.activatedCard}
                                modelEntered={this.modelEntered}
                                closeRewardModelHandler={(isActivated) =>
                                  this.closeRewardModelHandler(
                                    isActivated,
                                    item
                                  )
                                }
                              >
                                {item.rewards.length < 2 ? (
                                  <div>
                                    <div className="reveal-icon">
                                      {this.state.activatedCard &&
                                        this.state.activatedCard.length > 0 && (
                                          <img
                                            src={
                                              this.state.activatedCard[0]
                                                .image_url
                                            }
                                            alt="rewards icon."
                                            title="rewards icon."
                                            className="d-block"
                                          />
                                        )}
                                      {this.state.activatedCard && (
                                        <div className="reward-content">
                                          {this.state.activatedCard.length >
                                            0 ? (
                                            <React.Fragment>
                                              <h5>
                                                {" "}
                                                <FormattedMessage
                                                  id="general.congrats"
                                                  defaultMessage="Congratulations!"
                                                />
                                              </h5>
                                              <p>{item.rewards[0]?.display_name}</p>
                                              <p>
                                                <WonCardTitle
                                                  card={
                                                    this.state.activatedCard[0]
                                                  }
                                                />
                                              </p>
                                              <small>
                                                {" "}
                                                <FormattedMessage
                                                  id="rewards.won-on"
                                                  defaultMessage="Won on"
                                                />{" "}
                                                {this.state.activatedCard &&
                                                  this.state.activatedCard
                                                    .length > 0 &&
                                                  this.state.activatedCard[0]
                                                    .winning_date}
                                              </small>
                                            </React.Fragment>
                                          ) : (
                                            <>
                                              <img
                                                src={noWinImg}
                                                alt="rewards icon."
                                                title="rewards icon."
                                                className="d-block"
                                              />
                                              <p style={{ marginTop: "-8rem" }}>
                                                <FormattedMessage
                                                  id="rewards.oops"
                                                  defaultMessage="Oops!"
                                                />
                                              </p>
                                              <h5>
                                                <FormattedMessage
                                                  id="rewards.no-win"
                                                  defaultMessage="Better luck next time"
                                                />
                                              </h5>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <MultipleVochers />
                                )}
                              </SratchCardModel>
                            )}
                        </React.Fragment>
                      )}
                    {item.card_type === "unused-card" &&
                      item.type === "spin-wheel" && (
                        <React.Fragment>
                          <div
                            className="reward-icon"
                            onClick={() => this.openWheelModel(index)}
                          >
                            <img
                              src={item.image_url}
                              alt="rewards icon."
                              className="d-block"
                              title="rewards icon."
                            />
                            <h4 className="text-white position-absolute abs-center">
                              <FormattedMessage
                                id="rewards.spin"
                                defaultMessage="Tap To Spin"
                              />
                            </h4>
                          </div>

                          {this.state.isWheelModelOpen &&
                            this.state.currentIndex === index && (
                              <Wheel
                                item={item}
                                finished={() => this.finished(item)}
                                closeWheelModelHandler={(
                                  isActivated,
                                  isClaimed
                                ) =>
                                  this.closeWheelModelHandler(
                                    isActivated,
                                    isClaimed
                                  )
                                }
                                activatedCard={this.state.activatedCard}
                              />
                            )}
                        </React.Fragment>
                      )}
                    {item.card_type === "won-card" && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => this.wonCardDetail(item)}
                      >
                        <div className="reveal-icon">

                          <img src={item.image_url} alt="rewards icon." title="rewards icon."  className="d-block" />
                          <div className="rewards-won-card-textbox">
                            <div className="rewards-text-box-height">
                              <h6> {item.display_name}{" "}</h6>
                              <h3 className="text-won m-0"><WonCardTitle card={item} /></h3>
                            </div>
                            <p className="text-secondary reward-date mt-2">{item.winning_date}</p>
                          </div>

                          {item.type === "item" &&
                            (item.processed ? (
                              <small className="text-secondary">
                                <FormattedMessage
                                  id="rewards.claimed-prize"
                                  defaultMessage="Prize claimed"
                                />
                              </small>
                            ) : this.state.loading &&
                              index === this.state.currentIndex ? (
                              "loading"
                            ) : (
                              <small
                                className="text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.claimPrize(index, item.id)}
                              >
                                <FormattedMessage
                                  id="rewards.claim-prize"
                                  defaultMessage=" Claim Prize"
                                />
                              </small>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
          <p style={{ textAlign: "center" }} id="load" ref={this.loadRef}></p>
          {!this.state.isLastPage && (
            <div style={{ textAlign: "center" }}>
              <InlineLoader />
            </div>
          )}
        </div>

        <div className="row mb-3" id="offers">
          <div className="col-12">
            <h2 className="font-proxima-nova-bold d-inline-block">
              <FormattedMessage id="offers.offers" defaultMessage="Offers" />
            </h2>
          </div>
        </div>

        <div className="row mt-3 mb-5 font-proxima-nova">
          {this.state.isFetchingOffers ? (
            <div className="col-12">
              <CoverPageSkeleton rows={1} columns={4} height={40} width={40} />
            </div>
          ) : this.state.offers.length === 0 ? (
            <div className="empty-state">
              <EmptyState
                title={
                  <FormattedMessage
                    id="offers.no-offers"
                    defaultMessage="Currently, we don’t have any active offers, please come back again later"
                  />
                }
                image={EmptyImage}
                imageWidth={40}
              />
            </div>
          ) : (
            this.state.offers.map((item, index) => {
              return (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" key={`offer${index}`}>
                  <div className="offer-card d-flex">
                    <div className="card-icon rewards-card">
                      <div className="reward-icon d-flex justify-content-center">
                        <img
                          src={item.image_url}
                          alt="rewards icon."
                          className="d-block"
                          title="rewards icon."
                        />
                      </div>
                    </div>
                    <h4>{item.title}</h4>
                    <p className="text-secondary">{item.description}</p>
                    <small className="text-primary">
                      {" "}
                      <Link to={`/rewards/offers/${item.id}`}>
                        <FormattedMessage
                          id="offers.view-offer-details"
                          defaultMessage="View offer details"
                        />
                      </Link>
                    </small>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

export default connect(mapStateToProps, null)(Rewards);
