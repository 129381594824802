import React, { Component } from "react";
import { Link } from "react-router-dom";
import MultiItemCarousel from "../../../components/MultiItemCarouselHome";
import arrow from "../../../assets/home/ic_view_arrow.svg"

export default class BillPayCarousel extends Component {
  state = {
    billPayments: [
      "radial-gradient(#648CEA, #C1D4F0)",
      "radial-gradient(#5AB0C3, #C1E7F0)",
      "radial-gradient(#E99940, #F7D9B6)",
      "radial-gradient(#DD626C, #F6ACB2)",
      "radial-gradient(#E58066, #FCCDC3)",
      "radial-gradient(#648CEA, #C1D4F0)",
      "radial-gradient(#5AB0C3, #C1E7F0)",
      "radial-gradient(#E99940, #F7D9B6)",
      "radial-gradient(#DD626C, #F6ACB2)",
      "radial-gradient(#E58066, #FCCDC3)",
      "radial-gradient(#648CEA, #C1D4F0)",
      "radial-gradient(#5AB0C3, #C1E7F0)",
      "radial-gradient(#E99940, #F7D9B6)",
      "radial-gradient(#DD626C, #F6ACB2)",
      "radial-gradient(#E58066, #FCCDC3)",
    ]
  }

  render() {
    return (
      <MultiItemCarousel>
        {this.props.services.map((item, index) => {
          return (
            <Link to={`/bill-pay/${item?.slug}`} key={index}>
              <div className="billpay-box">
                <div className="billpay-box-img" style={{ background: `${this.state.billPayments[index]}` }}>
                  <img style={{ width: "100%" }} src={item.web_image_url} alt={item.name} title={item.name} />
                </div>
                <div className="billpay-label">{item.name}
                  <img src={arrow} alt="arrow" title="arrow" />
                </div>
              </div>
            </Link>
          );
        })}
      </MultiItemCarousel>
    )
  }
}
