import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import bannerClientX from "../../../utils/api/bannerClientX";
import WindsLoader from "../../../components/WindsLoader";
import InlineLoader from "../../../components/InlineLoader";
import { useHistory } from "react-router-dom";
import hotTag from "../../../assets/tag-hot.gif";
import newTag from "../../../assets/tag-new.gif";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";

const Deals = () => {
  const [dealsList, setDealsList] = React.useState([]);
  const ref = React.useRef();
  let history = useHistory();
  const [isLoading, setLoading] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(0);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [isLast, setLast] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    bannerClientX
      .get(`/v1/deals`, {
        params: {
          platform: "web",
          featured: 0,
          page: 1,
        },
      })
      .then((response) => {
        setDealsList(response.data.data.deals);
        setLoading(false);
        setLoadingMore(false);
      });
  }, []);

  React.useEffect(() => {
    if (!isLast && pageNo > 1 && !isLoading) {
      setLoadingMore(true);
      bannerClientX
        .get(`/v1/deals`, {
          params: {
            platform: "web",
            featured: 0,
            page: pageNo,
          },
        })
        .then((response) => {
          setLoadingMore(false);
          setLoading(false);
          response.data.data.deals.length > 0 &&
            setDealsList(dealsList.concat(response.data.data.deals));
          response.data.data.meta.last_page === pageNo && setLast(true);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPageNo((pageNo) => pageNo + 1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  const shopNow = (deal) => {
    const id = deal.id;
    const { segment, value } = deal.payload;
    history.push(`${segment}/${value}/${id}`);
  };

  return (
    <div className="container-fluid font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <small>
            <Link to="/">
              <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
            </Link>{" "}
            /{" "}
            <strong>
              <FormattedMessage
                id="breadcrumbs.deals"
                defaultMessage="Deals of the Day"
              />
            </strong>
          </small>
        </div>
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4 deals-page">
        {isLoading ? (
          <WindsLoader
            rows={2}
            columns={9}
            height={100}
            width={300}
            padding={1}
            speed={1}
          />
        ) : dealsList && dealsList.length > 0 ? (
          dealsList.map((deal, index) => {
            return (
              <div className="col-12 col-lg-3 col-md-5" key={`dealno${index}`}>
                <div className="deal-card">
                  <div className="deal-card-header">
                    <img src={deal.brand_image} alt="brand logo." title="brand logo" />
                    {deal.tag === "hot" && (
                      <img src={hotTag} alt="tag" title="tag" className="tag-img" />
                    )}
                    {deal.tag === "new" && (
                      <img src={newTag} alt="tag" title="tag" className="tag-img" />
                    )}
                  </div>
                  <div className="deal-body">
                    <h6 className="deal-title">{deal.product_name}</h6>
                    <div className="row">
                      <div className="col-7">
                        <img src={deal.image_url} alt="product." title="product" />
                      </div>
                      <div className="col-5">
                        <div className="deal-price-wrapper">
                          <h5 className="mrp">₹ {deal.actual_price}</h5>
                          <h5 className="offer-price">₹ {deal.offer_price}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="deal-footer">
                    <button
                      className="btn btn-primary home-btn w-50"
                      onClick={() => shopNow(deal)}
                    >
                      <FormattedMessage
                        id="general.shop-now"
                        defaultMessage="SHOP NOW"
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="empty-state">
            <EmptyState
              title={
                <FormattedMessage
                  id="deals.no-deal"
                  defaultMessage="No deals at the moment"
                />
              }
              imageWidth={30}
              image={EmptyImage}
            />
          </div>
        )}
        {loadingMore}
        {loadingMore && <InlineLoader />}
        <p ref={ref} id="loadingIcon"></p>
      </div>
    </div>
  );
};
export default Deals;
