import React, { Component } from "react";
import ShoppingSitesCarousel from "../components/ShoppingSitesCarousel";
import NearbyPartnerCarousel from "../components/NearbyPartnerCarousel";
import VoucherCarousel from "../components/VoucherCarousel";
import OffersCarousel from "../components/OffersCarousel";
import { Link } from "react-router-dom";
import lodash from "lodash";
import { FormattedMessage } from "react-intl";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import customerCommonClientX from "../../../utils/api/customerCommonClientX";
import voucherClientX from "../../../utils/api/voucherClientX";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import customerAffiliateClientX from "../../../utils/api/customerAffiliateClientX";

export default class Bookmarks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookmarks: null,
      isLoading: false,
    };
  }

  deleteBookmark = (id, url, itemToDeleteFromTheList) => {
    let axiosClient = this.getAxiosClients(itemToDeleteFromTheList)
    axiosClient
      .delete(url)
      .then((response) => {
        this.setState((prevState) => ({
          bookmarks: {
            ...prevState.bookmarks,
            [itemToDeleteFromTheList]: prevState.bookmarks[
              itemToDeleteFromTheList
            ].filter((bookmark) => bookmark.id !== id),
          },
        }));
      })
      .catch((error) => {});
  };

  getAxiosClients = (key) => {
    switch (key) {
      case 'vouchers':
        return voucherClientX
      case 'partners':
        return customerPartnerClientX
      case 'affiliates':
        return customerAffiliateClientX
      case 'offers':
        return customerCommonClientX
      default:
        break;
    }
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        customerCommonClientX.get("v1/bookmarks").then((response) => {
          this.setState({
            bookmarks: response.data.data,
            isLoading: false,
          });
        });
      }
    );
  }

  render() {
    const  {isLoading,bookmarks} = this.state;
    return (
      <React.Fragment>
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-12">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>{" "}
                /{" "}
                <strong>
                  <FormattedMessage
                    id="navbar.bookmarks"
                    defaultMessage="Bookmarks"
                  />
                </strong>
              </small>
            </div>
          </div>
        </div>

        {isLoading ? (
          <this.Shimmer />
        ) : null == bookmarks ||
          (bookmarks.affiliates.length === 0 &&
            bookmarks.offers.length === 0 &&
            bookmarks.partners.length === 0 &&
            bookmarks.vouchers.length === 0) ? (
          <this.EmptyState />
        ) : (
          
          <this.Bookmarks />
        )}
      </React.Fragment>
    );
  }

  Shimmer = (props) => {
    return (
      <React.Fragment>
        {lodash.range(4).map((index) => {
          return (
            <div
              className="container container-fluid--home-section"
              key={index}
            >
              <div className="row row--home-section-title">
                <div className="col-md-12">
                  <div className="heading shine"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3 d-none d-md-block">
                  <div className="box shine"></div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  EmptyState = (props) => {
    return (
      <div className="empty-state">
        <EmptyState
          title={
            <FormattedMessage
              id="bookmark.no-bookmark"
              defaultMessage="You don't have any bookmarks."
            />
          }
          imageWidth={25}
          image={EmptyImage}
        />
      </div>
    );
  };

  Bookmarks = (props) => {
    const {bookmarks} = this.state;
    return (
      <React.Fragment>
        {bookmarks.affiliates !== undefined &&
          bookmarks.affiliates.length > 0 && (
            <div className="container mt-3 mt-md-4">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.shopping-sites"
                      defaultMessage="Shopping Sites"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ShoppingSitesCarousel
                    affiliate={bookmarks.affiliates}
                    deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                      this.deleteBookmark(id, url, itemToDeleteFromTheList);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {bookmarks.partners !== undefined &&
         bookmarks.partners.length > 0 && (
            <div className="container mt-3 mt-md-4">
              <div className="row">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.partners"
                      defaultMessage="Partners"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <NearbyPartnerCarousel
                    partners={bookmarks.partners}
                    deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                      this.deleteBookmark(id, url, itemToDeleteFromTheList);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {bookmarks.vouchers !== undefined &&
          bookmarks.vouchers.length > 0 && (
            <div className="container mt-3 mt-md-4">
              <div className="row">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.vouchers"
                      defaultMessage="Vouchers"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <VoucherCarousel
                    vouchers={bookmarks.vouchers}
                    deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                      this.deleteBookmark(id, url, itemToDeleteFromTheList);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {bookmarks.offers !== undefined &&
         bookmarks.offers.length > 0 && (
            <div className="container mt-3 mt-md-4">
              <div className="row">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.offers-near-you"
                      defaultMessage="Offers Near You"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <OffersCarousel
                    offers={bookmarks.offers}
                    deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                      this.deleteBookmark(id, url, itemToDeleteFromTheList);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  };
}
