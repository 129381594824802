import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import InlineLoader from "../../../components/InlineLoader";
import useLoadMoreData from "../../../hooks/useLoadMoreData";
import { CoverPageSkeleton } from "../../../components/skeleton";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
// import wrpSummaryClientX from '../../../utils/api/wrpSummaryClientX';


const WrpSummary = (props) => {
  const [isConfirmedDivShown, setIsConfirmedDivShown] = useState(false);
  const [currentConfirmedDivIndex, setIsConfirmedDivIndex] = useState(0);
  let history = useHistory();
  const [tab, setTab] = useState(1);
  let limit = 10;
  const confirmedWrpState = useLoadMoreData("/v1/confirmed-summary", limit);
  let confirmedSummary = confirmedWrpState.responseData;
  let isConfirmedSummarLoading = confirmedWrpState.isLoading;
  let isLastPageOfConfirmedSummary = confirmedWrpState.isLastpage;
  let confirmedWrp = confirmedWrpState.wrp;
  let isViewMoreConfirmedSummaryLoading = confirmedWrpState.isFetchingMore;
  const viewMoreConfirmedSummary = () => {
    confirmedWrpState.incrementPageNo();
  };

  const pendingWrpState = useLoadMoreData("/v1/pending-summary", limit);
  let pendingSummary = pendingWrpState.responseData;
  let isPendingSummaryLoading = pendingWrpState.isLoading;
  let isLastPageOfPendingSummary = pendingWrpState.isLastpage;
  let isViewMorePendingSummaryLoading = pendingWrpState.isFetchingMore;
  let pendingWrp = Math.round(pendingWrpState.wrp);
  const viewMorePendingSummary = () => {
    pendingWrpState.incrementPageNo();
  };

  // const redeemOrdersState = useLoadMoreData("/v1/list-redeem-orders", limit);
  // let redeemOrders = redeemOrdersState.responseData;
  // let isRedeemOrdersLoading = redeemOrdersState.isLoading;
  // let isLastPageOfRedeemOrders = redeemOrdersState.isLastpage;
  // let isViewMoreRedeemOrdersLoading = redeemOrdersState.isFetchingMore;
  // let redeemWrp = Math.round(redeemOrdersState.wrp);
  // const viewMoreRedeemOrders = () => {
  //   redeemOrdersState.incrementPageNo();
  // };

  const toggleTabs = (tabNo) => {
    setTab(tabNo);
  };

  const viewOrder = (type, orderId) => {
    if (type === "partner-purchase") {
      history.push(`/nearby-partners/orders/${orderId}`);
    } else if (type === "voucher") {
      history.push(`/vouchers/orders/${orderId}`);
    } else if (type === "shopping-site") {
      history.push(`/shopping-sites/orders/${orderId}`);
    } else if (type === "travel") {
      history.push(`/flights/orders/${orderId}`)
    } else if (type === "hotel") {
      history.push(`/hotels/orders/${orderId}`)
    } else if (type === "winds_cart"){
      history.push(`/winds-merchandise/orders/${orderId}`)
    } else if (type === 'ecommerce') {
      window.open(`${process.env.REACT_APP_ECOMMERCE}/customer/account/orders/view/${orderId}`)
    } else {
      history.push(`/bill-pay/orders/${orderId}`);
    }
  };

  const onMouseEnterOnConfirmedTab = (index) => {
    setIsConfirmedDivShown(true);
    setIsConfirmedDivIndex(index);
  };
  const onMouseLeaveOnConfirmedTab = (index) => {
    setIsConfirmedDivShown(false);
    setIsConfirmedDivIndex(index);
  };

  // const viewNow = (orderData) => {
  //   const { wrp } = orderData;
  //   wrpSummaryClientX.get(`/v1/view-redeem-order/${orderData.id}`).then(response => {
  //     const responseData = { ...{ wrp }, ...response.data.data };
  //     props.viewMoreRedeemedOrder(responseData);
  //   })
  // }

  return (
    <Modal show onHide={props.onDismiss} className="font-proxima-nova wrp-summary-modal">
      <Modal.Header closeButton className="border-0 text-center"></Modal.Header>
      <Modal.Body className="text-center font-proxima-nova wrp-summary wrp-tab-summary">
        <h4 className="wrpTitle">
          <strong>
            <FormattedMessage id="wrp.summary" defaultMessage="WINDS Coins History" />
          </strong>
        </h4>
        {/* <div>
          <div className="toggle-tabs-wrapper mt-4">
            <div
              className={`tab-btn font-proxima-nova ${tab === 1 ? "active" : ""}`}
              onClick={() => toggleTabs(1)}
            >
              <FormattedMessage
                id="wrp.confirmed-wrp"
                defaultMessage="Confirmed WRP"
              />
            </div>
            <div
              className={`tab-btn font-proxima-nova  ${tab === 2 ? "active" : ""}`}
              onClick={() => toggleTabs(2)}
            >
              <FormattedMessage
                id="wrp.pending-wrp"
                defaultMessage="Provisional WRP"
              />
            </div>
            <div
              className={`tab-btn font-proxima-nova ${tab === 3 ? "active" : ""}`}
              onClick={() => toggleTabs(3)}
            >
              <FormattedMessage
                id="goa.redeemed"
                defaultMessage="Redeemed WRP"
              />
            </div>
          </div>
        </div> */}
        {/* {tab === 1 ? ( */}
          <React.Fragment>
            <div className="border-bottom mt-4 w-75 mx-auto" id="confirmedWRP">
              <h6 classsName="earnedwrp">
                <FormattedMessage
                  id="wrp.earned-wrp"
                  defaultMessage="WINDS Coins Earned"
                />
              </h6>
              <h3 className="wrpNumber">
                <strong>{confirmedWrp || 0} WINDS Coins</strong>
              </h3>
            </div>
            <div className="wrp-content p-0 pl-lg-5 pr-lg-5 pl-md-5 pr-md-5">
              <h5>
                <strong>
                  <FormattedMessage
                    id="wrp.history"
                    defaultMessage="WINDS Coins History"
                  />
                  :
                </strong>
              </h5>
              {isConfirmedSummarLoading ? (
                <CoverPageSkeleton
                  rows={1}
                  columns={2}
                  height={0.2}
                  width={0.2}
                />
              ) : confirmedSummary && confirmedSummary.length > 0 ? (
                confirmedSummary.map((entry, index) => {
                  return (
                    <div className="card mt-3" key={`confirm${index}`}>
                      <div className="card-body">
                        {
                          !entry.reversed ?

                            <div className="row">
                              <div className="col-6 text-left">
                                <h6>
                                  <strong>WINDS Coins</strong>
                                </h6>
                              </div>
                              <div className="col-6 text-right">
                                <h6>

                                  <strong className={`${entry.wrp < 0 ? 'error-text' : ''}`}>{entry.wrp}</strong>
                                </h6>
                              </div>
                            </div> :

                            <React.Fragment>
                              {
                                entry.reversed && !entry.partial_reversed &&
                                <div className="row">
                                  <div className="col-6 text-left">
                                    <h6>
                                      <strong>WINDS Coins</strong>
                                    </h6>
                                  </div>
                                  <div className="col-6 text-right">
                                    <h6>
                                      <strong className={`${entry.reversed && !entry.partial_reversed ? 'error-text' : ''}`}>{entry.reversed && !entry.partial_reversed ? '-' : ''}{entry.wrp}</strong>
                                    </h6>
                                  </div>
                                </div>
                              }
                              {
                                entry.reversed && entry.partial_reversed &&
                                <React.Fragment>
                                  <div className="row">
                                    <div className="col-6 text-left">
                                      <h6>
                                        <strong>WINDS Coins</strong>
                                      </h6>
                                    </div>
                                    <div className="col-6 text-right">
                                      <h6>
                                        <strong >{entry.wrp}</strong>
                                      </h6>
                                    </div>
                                  </div>
                                  {/* <div className="row">
                                    <div className="col-6 text-left">
                                      <h6>
                                        <strong><FormattedMessage
                                          id="order.actual-wrp"
                                          defaultMessage="Initial WRP"
                                        /></strong>
                                      </h6>
                                    </div>
                                    <div className="col-6 text-right">
                                      <h6>

                                        <strong>{entry.wrp}</strong>
                                      </h6>
                                    </div>
                                  </div> */}
                                  <div className="row">
                                    <div className="col-6 text-left">
                                      <h6>
                                        <strong><FormattedMessage
                                          id="order.reversed-wrp"
                                          defaultMessage="Reversed WINDS Coins"
                                        /></strong>
                                      </h6>
                                    </div>
                                    <div className="col-6 text-right">
                                      <h6>

                                        <strong className={`${entry.wrp < 0 || entry.reversed ? 'error-text' : ''}`}>{entry.reversed ? '-' : ''}{entry.reversed_wrp}</strong>
                                      </h6>
                                    </div>
                                  </div></React.Fragment>
                              }


                            </React.Fragment>
                        }

                        <div className="row">
                          <div className="col-7 text-secondary text-left">
                            <p style={{ marginBottom: "-.3rem" }}>
                              {entry.service_text}
                              {entry.order_number !== "#" && entry.order_number}
                            </p>
                            <small>{entry.credited_at}</small>
                          </div>
                          <div className=" col-5 text-md-right text-left mt-3 p-0">
                            {entry.service !== "referral" &&
                              entry.service !== "accountregistration" &&
                              entry.service !== "deduction" &&
                              entry.service !== "reward-system" && 
                              entry.service !== "ppi_card_transaction" && (
                                <button
                                  className="btn btn-white winds-link-color"
                                  onClick={() =>
                                    viewOrder(
                                      entry.service,
                                      entry.item_order_id
                                    )
                                  }
                                >
                                  <FormattedMessage
                                    id="wrp.view-order"
                                    defaultMessage="View Order"
                                  />{" "}
                                </button>
                              )}
                            {entry.service === "deduction" && (
                              <button
                                className="btn btn-white winds-link-color"
                                onMouseEnter={() =>
                                  onMouseEnterOnConfirmedTab(index)
                                }
                                onMouseLeave={() =>
                                  onMouseLeaveOnConfirmedTab(index)
                                }
                              >
                                <FormattedMessage
                                  id="wrp.view-detail"
                                  defaultMessage="View Details"
                                />{" "}
                              </button>
                            )}
                          </div>
                          {isConfirmedDivShown &&
                            currentConfirmedDivIndex === index && (
                              <div className="wrp-tip-wrapper">
                                <div className="wrp-tip">
                                  {entry.comment}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                "No data available"
              )}
            </div>
            {isViewMoreConfirmedSummaryLoading ? (
              <InlineLoader />
            ) : (
              !isLastPageOfConfirmedSummary && (
                <button
                  className="btn btn-primary home-btn mt-4"
                  onClick={viewMoreConfirmedSummary}
                >
                  <FormattedMessage
                    id="wrp.view-more"
                    defaultMessage="View More"
                  />
                </button>
              )
            )}
          </React.Fragment>
        {/* ) : tab === 2 ? (
          <React.Fragment>
            <div className="border-bottom mt-4 w-75 mx-auto" id="pendingWRP">
              <h6 classsName="earnedwrp">
                <FormattedMessage
                  id="wrp.earned-wrp"
                  defaultMessage="WRP earned in this cycle"
                />
              </h6>
              <h3 className="wrpNumber">
                <strong>{pendingWrp || 0} WRP</strong>
              </h3>
            </div>
            <div className="wrp-content p-0 pl-lg-5 pr-lg-5 pl-md-5 pr-md-5">
              <h5>
                <strong>
                  <FormattedMessage
                    id="wrp.history"
                    defaultMessage="WRP History"
                  />
                  :
                </strong>
              </h5>
              {isPendingSummaryLoading
                ? "loading"
                : pendingSummary && pendingSummary.length > 0
                  ? pendingSummary.map((entry, index) => {
                    return (
                      <div className="card mt-3" key={`pendingSummary${index}`}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-6 text-left">
                              <h6>
                                <strong>WRP</strong>
                              </h6>
                            </div>
                            <div className="col-6 text-right">
                              <h6>
                                <strong>{entry.wrp}</strong>
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-7 text-secondary text-left">
                              <p style={{ marginBottom: "-.3rem" }}>
                                {entry.service_text}
                                {entry.order_number !== "#" && entry.order_number}
                              </p>
                              <small>{entry.credited_at}</small>
                            </div>
                            <div className="col-5 col text-md-right text-left mt-3 p-0">
                              {entry.service !== "referral" &&
                                entry.service !== "accountregistration" &&
                                entry.service !== "deduction" &&
                                entry.service !== "reward-system" && (
                                  <button
                                    className="btn btn-white winds-link-color"
                                    onClick={() =>
                                      viewOrder(
                                        entry.service,
                                        entry.item_order_id
                                      )
                                    }
                                  >
                                    <FormattedMessage
                                      id="wrp.view-order"
                                      defaultMessage="View Order"
                                    />{" "}
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : "No data available"}
            </div>
            {isViewMorePendingSummaryLoading ? (
              <InlineLoader />
            ) : (
              !isLastPageOfPendingSummary && (
                <button
                  className="btn btn-primary home-btn mt-4"
                  onClick={viewMorePendingSummary}
                >
                  <FormattedMessage
                    id="home.view-more"
                    defaultMessage="View More"
                  />
                </button>
              )
            )}
          </React.Fragment>
        ) : (
          <>
            <React.Fragment>
          <div className="border-bottom mt-4 w-75 mx-auto" id="pendingWRP">
            <h6 classsName="earnedwrp">
              <FormattedMessage
                id="wrp.earned-wrp"
                defaultMessage="WRP redeemed in this cycle"
              />
            </h6>
            <h3 className="wrpNumber">
              <strong>{redeemWrp || 0} WRP</strong>
            </h3>
          </div>
          <div className="wrp-content p-0 pl-lg-5 pr-lg-5 pl-md-5 pr-md-5 ">
            <h5 className="wrpText w-50 ">
              <strong>
                <FormattedMessage
                  id="wrp.history"
                  defaultMessage="WRP History"
                />
                :
              </strong>
            </h5>
            <span><a className="claimNowLink"
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_REDIRECT_GOA}?uname=${props.profile.name}&uemail=${props.profile.email}&umobile=${props.profile.mobile}`}
            ><FormattedMessage id="goa.claim" defaultMessage="CLAIM NOW" /></a></span>
            {isRedeemOrdersLoading
              ? <CoverPageSkeleton
                rows={1}
                columns={2}
                height={0.2}
                width={0.2}
              />
              : redeemOrders && redeemOrders.length > 0
                ? redeemOrders.map((entry, index) => {
                  return (
                    <div className="card mt-3" key={`redeemOrders${index}`}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6 text-left">
                            <h6>
                              <strong><FormattedMessage id="goa.wrp-redeemed" defaultMessage="WRP Redeemed" /> - {entry.wrp}</strong>
                            </h6>
                          </div>
                          <div className="col-6 text-right">
                            <h6>
                              <strong>&#x20b9;&nbsp;{entry.amount}</strong>
                            </h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-7  text-secondary text-left">
                            <p style={{ marginBottom: "-.3rem" }}>
                              <FormattedMessage id="goa-voucher-purchase" defaultMessage="Voucher Purchase" />   &nbsp;#{entry.id}
                            </p>
                            {
                              entry.is_partial_used &&
                              <p style={{ marginBottom: "-.3rem" }}>
                                <small>  <FormattedMessage id="goa-avaiable-balance" defaultMessage="Available balance" /> : {entry.balance}</small>
                              </p>
                            }
                            <p style={{ marginBottom: "-.3rem" }}>

                              <small>{entry.fulfilled_at}</small>
                            </p>

                          </div>
                          <div className="col-5  text-md-right text-left mt-3 p-0">
                            {
                              entry.is_used ?
                                entry.is_partial_used ?
                                  <button
                                    className="btn btn-white winds-link-color"
                                    style={{ 'background': '#0078D7' }}
                                    onClick={() =>
                                      viewNow(
                                        entry
                                      )
                                    }
                                  >
                                    <FormattedMessage
                                      id="goa.redemmed"
                                      defaultMessage="Redeemed Voucher"
                                    />{" "}
                                  </button> :
                                  <button
                                    className="btn btn-white winds-link-color"
                                    style={{ 'background': '#0078D7' }}
                                    onClick={() =>
                                      viewNow(
                                        entry
                                      )
                                    }
                                  >
                                    <FormattedMessage
                                      id="goa.redemmed"
                                      defaultMessage="Redeemed Voucher"
                                    />{" "}
                                  </button>

                                :
                                <button
                                  className="btn btn-white winds-link-color"

                                  onClick={() =>
                                    viewNow(
                                      entry
                                    )
                                  }
                                >
                                  <FormattedMessage
                                    id="voucher.view"
                                    defaultMessage="View Voucher"
                                  />{" "}
                                </button>

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                : "No data available"}
          </div>
          {isViewMoreRedeemOrdersLoading ? (
            <InlineLoader />
          ) : (
            !isLastPageOfRedeemOrders && (
              <button
                className="btn btn-primary home-btn mt-4"
                onClick={viewMoreRedeemOrders}
              >
                <FormattedMessage
                  id="home.view-more"
                  defaultMessage="View More"
                />
              </button>
            )
          )}
        </React.Fragment>

          </>
        )} */}
      </Modal.Body>
    </Modal >
  );
};

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}


export default connect(
  mapStateToProps,
  null
)(WrpSummary);

