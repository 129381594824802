import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { withRouter } from "react-router-dom";
import { onBannerClicked } from "../../../utils/events";

const BannerCarousel = (props) => {
  const [index, setIndex] = React.useState(0);
  const [direction, setDirection] = React.useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const slide = (index, event) => {
    setDirection(event.direction);
  };

  const onBannerClickEvent = (banner, index) => {
    if (null == banner.link) {
      return;
    }
    if (banner.link.startsWith("http")) {
      window.open(banner.link);
    }
    const payload = {
      "Banner Title": banner.title,
      "Banner ID" : banner.id
    };
    onBannerClicked(payload);
  };

  return (
    <Carousel className="px-lg-5 mx-xl-4"
      activeIndex={index}
      direction={direction}
      onSelect={handleSelect}
      onSlide={slide}

    >
      {props.banner.map((item, index) => {
        return (
          <Carousel.Item key={`bannerID${index}`}className="pb-lg-5">
            <img
              className="d-block w-100" 
              onClick={() => {
                onBannerClickEvent(item, index);
              }}
              style={{maxHeight:'360px',objectFit:"cover"}}
              src={item.image_url}
              alt={item.title}
              title={item.title}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default withRouter(BannerCarousel);
