import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import Iframe from "../../../components/iFrame";
import { currentlySelectedLanguage } from "../../../utils/helper";
const WrpDetails = (props) => {
  return (
    <div className="container font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <small>
            <Link to="/">
              <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
            </Link>{" "}
            /{" "}
            <strong>
              <FormattedMessage id="wrp.details" defaultMessage="WINDS Coins Details" />
            </strong>
          </small>
        </div>
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
        <Iframe
        source={`${process.env.REACT_APP_BASE_URL_WRP_DETAILS}/${currentlySelectedLanguage()}`}
      ></Iframe>
        </div>
      </div>
    </div>
  );
};
export default injectIntl(WrpDetails);
