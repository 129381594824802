export function setHome(
  banner,
  heading_text,
  affiliate,
  popular_affiliate,
  partner,
  voucher,
  billpay,
  offers,
  tags,
  deals,
  ecommerce,
  insurance,
  service_categories,
  rewards,
  fun_game_zone,
  winds_kart,
  monetized_affiliate
  // customer_order_commission,
  // wrp,
) {
  return {
    type: "SET_HOME",
    banner,
    heading_text,
    affiliate,
    popular_affiliate,
    partner,
    voucher,
    billpay,
    offers,
    tags,
    deals,
    ecommerce,
    insurance,
    service_categories,
    rewards,
    fun_game_zone,
    winds_kart,
    monetized_affiliate
    // customer_order_commission,
    // wrp,
  };
}

export function setHomeAfterBookmarkToggle(
  id,
  listTypeToUpdate,
  banner,
  affiliate,
  partner,
  voucher,
  billpay,
  offers,
  tags,
  // customer_order_commission,
  // wrp
) {
  return {
    type: "SET_HOME_AFTER_TOGGLE_BOOKMARK",
    id,
    listTypeToUpdate,
    banner,
    affiliate,
    partner,
    voucher,
    billpay,
    offers,
    tags,
    // customer_order_commission,
    // wrp
  };
}

export function setLoading(isLoading) {
  return {
    type: "SET_LOADING",
    isLoading: isLoading,
  };
}

export function setMonetizedAffiliate(monetizedAffiliate) {
  return {
    type: "SET_MONETIZED_AFFILIATE",
    monetized_affiliate: monetizedAffiliate,
  };
}

export function setCounterData(
  active_coupons,
  total_points,
  pending_points,
  coupon_progression_percent,
  total_points_per_coupon
) {
  return {
    type: "SET_COUNTER_DATA",
    active_coupons,
    total_points,
    pending_points,
    coupon_progression_percent,
    total_points_per_coupon,
  };
}

export function getWrp() {
  return {
    type: "GET_WRP",
  };
}

export function setWrp(wrp) {
  return {
    type: "SET_WRP",
    wrp
  };
}

export function resetWrp() {
  return {
    type: "RESET_WRP",
  };
}

export function setCompleteProfileCount(count) {
  return {
    type: "SET_COMPLETE_PROFILE_COUNT",
    count
  };
}