import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { OrderStatusLabel, showToastMessage } from "../../../utils/helper";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import voucherClientX from "../../../utils/api/voucherClientX";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import supportImg from "../../../assets/billpay/ic_support.svg";
import arrowBlue from "../../../assets/billpay/ic_arrow_blue.svg";
import ic_rupee_symbol from "../../../assets/billpay/ic_rupee_symbol.svg";
import wrpCoin from "../../../assets/billpay/ic_wrp_coin.svg";
import ic_download from "../../../assets/billpay/ic_download.svg";
import ic_invoice from "../../../assets/billpay/ic_invoice.svg";
import { Modal, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ic_refresh from '../../../assets/orders/ic_refresh.svg'
import ic_copy from '../../../assets/orders/ic_copy.svg'
import ic_reward from '../../../assets/orders/ic_reward_coin.png'
import ic_dropdown from '../../../assets/orders/dropdown.png'
import ic_voucher from '../../../assets/orders/ic_voucher.png'
import ic_redeem from "../../../assets/orders/ic_redirect.svg"
import { claimNow, claimed } from "../../../components/RewardsClaim";
import Lottie from "lottie-react";
import SuccessBg from '../../../assets/voucher/ic_confetti.json'

export default class OrderDetail extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      order: null,
      vouchers: [],
      voucherStatus: null,
      isFetchingVoucherCodes: false,
      canDownloadReceipt: false,
      bgColor: "",
      receiptDownloading: false,
      currentVoucher: "",
      currentSelectedVoucher: null,
      showRefreshStatus: false,
      isRadeemModalOpen: false,
      isVoucherDetailsModalOpen: false,
      show: false,
      displayedFirstTime: false
    };
    this.loadRef = React.createRef();

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  downloadReceipt = () => {
    this.setState({ receiptDownloading: true })
    voucherClientX
      .get(`/v1/order/${this.state.order.id}/receipt`)
      .then((response) => {
        this.setState({ receiptDownloading: false })
        window.open(response.data.data.receipt);
      });
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params },
    } = this.props;

    voucherClientX.get(`v1/orders/${params.id}`).then((response) => {
      let data = response.data.data;
      if (this._isMounted) {
        this.setState({
          order: data,
        });
        this.OrderStatusLabel(data)

        if (3 === parseInt(data.status) || 7 === parseInt(data.status)) {
          this.setState({
            canDownloadReceipt: true,
          });
        }

        if ((data.fulfilled && !data.voucher_code_generated) || data.status_code === 7) {
          this.setState({ voucherStatus: "pending" });
          return;
        }

        if (data.voucher_code_generated && data.status_code === 3) {
          this.process(params.id);
        }
      }
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.setState({ show: true })
            setTimeout(() => {
              this.setState({ displayedFirstTime: true })
            }, 100);
          }
          else {
            this.setState({ show: false })
          }
        });
      });
      this.observer?.observe(this.loadRef.current);
    });
  }

  openRadeemModalHandler = () => {
    this.setState({ isRadeemModalOpen: true });
  };
  closeRadeemModalHandler = () => {
    this.setState({ isRadeemModalOpen: false });
  };

  RadeemModal = (show, onHide) => {
    return (
      <React.Fragment>
        <Modal
          show={show}
          onHide={onHide}
          className="font-proxima-nova"
          size="md"
          scrollable
          centered
        >
          <Modal.Header closeButton className="border-0 w-100">
            <Modal.Title>
              <strong>How to Redeem</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4"
            id="recent-transaction-modal"
          >
            <img src={this.state?.order?.voucher_details?.redeem_image_path} alt="radeem-img" title="radeem-img" className="w-100" />
            {/* <div
              dangerouslySetInnerHTML={{
                __html: this.state?.order?.voucher_details?.redeem_text
              }}
            ></div> */}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };

  openVoucherDetailsModalHandler = () => {
    this.setState({ isVoucherDetailsModalOpen: true });
  };
  closeVoucherDetailsModelHandler = () => {
    this.setState({ isVoucherDetailsModalOpen: false });
  };

  VoucherDetailsModal = (show, onHide) => {
    return (
      <React.Fragment>
        <Modal
          show={show}
          onHide={onHide}
          className="font-proxima-nova"
          size="md"
          scrollable
          centered
        >
          <Modal.Header closeButton className="border-0 w-100">
            <Modal.Title>
              <strong>Voucher Details</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4"
            id="recent-transaction-modal"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: this.state?.order?.voucher_details?.description
              }}
            ></div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };

  handleRadeemTab = (redeemLink) => {
    if (redeemLink) {
      const isAbsoluteLink = /^(http|https):\/\//.test(redeemLink);
      const newPageUrl = isAbsoluteLink ? redeemLink : `http://${redeemLink}`;
      window.open(newPageUrl, '_blank');
    } else {
      // Handle the case when redeemLink is not available.
    }
  }

  Voucher = (props) => {
    function renderTooltip(properties) {
      return (
        <Tooltip {...properties} show="true">
          Click to Copy
        </Tooltip>
      );
    }
    const {
      order,
      vouchers,
      voucherStatus,
      isFetchingVoucherCodes,
    } = this.state;

    return (
      <React.Fragment>
        <div className="list-group-item py-3">
          <p className="mb-0"><small>Brand Vouchers</small></p>
          <p style={{ color: "#7D70FF" }} className="h5 mb-0"><strong>{order?.voucher_name}</strong></p>
          {((this.state?.order.voucher_code_generated && this.state?.order?.status_code === 3) || order?.status_code === 7) &&
            <>
              <ToggleButtonGroup
                type="radio"
                name="options"
                value={this.state.currentVoucher}
                onChange={(val) => {
                  this.filterCurrentVoucher(val)
                  this.setState({ currentVoucher: val })
                }}
                className="mb-4 d-row voucher-toggle-group">
                {vouchers && vouchers.length > 1 && vouchers.map((item, index) => {
                  return (
                    <ToggleButton checked={this.state?.currentVoucher === item?.voucher_number} className="m-2 squre-pill" value={item?.voucher_number} variant="outline-light" size="sm" >Voucher {index + 1}</ToggleButton>
                  );
                })
                }
              </ToggleButtonGroup>
              <div className="">
                {isFetchingVoucherCodes ? (
                  <div className="box shine"></div>
                ) : ("pending" === voucherStatus && order?.status_code !== 6) || this.state?.showRefreshStatus ? (
                  <>
                    <div className="d-flex flex-column flex-md-row">
                      <img
                        className="mt-2 mt-md-0"
                        src={order.web_image_url}
                        style={{
                          background: "#fff",
                          width: "25%",
                          // height: "60px",
                          border: "1px solid rgba(0, 0, 0, 0.125)",
                          display: "inline",
                          boxSizing: "content-box",
                          padding: "15px 0",
                          borderRadius: "0.25rem",
                          marginRight: "15px",
                        }}
                        alt={order.voucher_name}
                        title={order.voucher_name}
                      />
                      <div className="mt-1 mt-md-0 mb-0 d-flex flex-column justify-content-center">
                        <strong>{this.state.order?.code_not_generated_string?.title}</strong>
                        <p>{this.state.order?.code_not_generated_string?.details}</p>
                        <span className="font-weight-bold d-flex align-items-center cursor-pointer" style={{ color: "#6372ff", width: "fit-content" }} onClick={this.viewVoucher}>REFRESH STATUS
                          <img src={ic_refresh} alt="refresh" title="refresh" width="20px" className="ml-2" />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-column flex-md-row">
                      <img
                        className="mt-2 mt-md-0"
                        src={order.web_image_url}
                        style={{
                          background: "#fff",
                          // width: "25%",
                          height: "80px",
                          border: "1px solid rgba(0, 0, 0, 0.125)",
                          display: "inline",
                          boxSizing: "content-box",
                          padding: "15px 0",
                          borderRadius: "0.25rem",
                          marginRight: "15px",
                        }}
                        alt={order.voucher_name}
                        title={order.voucher_name}
                      />
                      {
                        this.state.currentSelectedVoucher &&
                        <>
                          <div className="mt-1 mt-md-0 mb-0 d-flex flex-column justify-content-start">
                            {this.state?.currentSelectedVoucher[0]?.voucher_number && this.state?.currentSelectedVoucher[0]?.voucher_number !== "N/A" && this.state?.currentSelectedVoucher[0]?.voucher_number !== "" &&
                              <>
                                <p className="mb-0"><small>Voucher Code</small></p>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <span className="font-weight-bold d-flex align-items-center justify-content-between cursor-pointer" style={{ color: "#6372ff" }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(this.state?.currentSelectedVoucher[0]?.voucher_number);
                                      showToastMessage(
                                        "success",
                                        "Voucher Code copied to the clipboard"
                                      );
                                    }}
                                  >
                                    <p className="mb-0 mr-2">{this.state?.currentSelectedVoucher[0]?.voucher_number || ''}</p>
                                    <img src={ic_copy} alt="copy" title="copy" width="20px" className="ml-2" />
                                  </span>
                                </OverlayTrigger>
                              </>
                            }
                            {this.state?.currentSelectedVoucher[0]?.voucher_pin && this.state?.currentSelectedVoucher[0]?.voucher_pin !== "N/A" && this.state?.currentSelectedVoucher[0]?.voucher_pin !== "" &&
                              <>
                                <p className="mb-0 mt-2"><small>Voucher PIN</small></p>
                                <span className="font-weight-bold d-flex align-items-center cursor-pointer justify-content-between" style={{ color: "#6372ff" }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(this.state.currentSelectedVoucher[0]?.voucher_pin);
                                    showToastMessage(
                                      "success",
                                      "Voucher PIN copied to the clipboard"
                                    );
                                  }}
                                >
                                  <p className="mb-0 mr-2">{this.state.currentSelectedVoucher[0]?.voucher_pin || 'N/A'}</p>
                                  <img src={ic_copy} alt="copy" title="copy" width="20px" className="ml-2" />
                                </span>
                              </>
                            }
                          </div>

                        </>

                      }
                    </div>
                    {this.state?.order?.voucher_details?.affiliate_route !== null &&
                      <>
                        <hr className="mt-4 mb-4" />
                        <div>
                          <button
                            className="btn mt-3 billpay-order-btn btn-width-50 mx-auto d-flex"
                            style={{ borderRadius: "6px" }}
                            disabled={this.state?.order?.voucher_details?.affiliate_route === "" && this.state?.order?.voucher_details?.redeem_link === null}
                            onClick={() => {
                              this.state?.order?.voucher_details?.redeem_link ?
                              this.handleRadeemTab(this.state?.order?.voucher_details?.redeem_link)
                              :
                              (this.state?.order?.voucher_details?.affiliate_route === "winds_gift_card" ?
                                this.props.history.push("/voucher/redeem") 
                                :
                                this.props.history.push(`/shopping-sites/${this.state?.order?.voucher_details?.affiliate_route}`))
                            }
                            }
                          >
                            <span>REDEEM NOW</span>
                            <img src={ic_redeem} className="ml-2" alt="redeem" title="redeem" />
                          </button>
                          <div className="text-center mt-3">
                            <p className="mb-0">
                              <span className="color-blue cursor-pointer" onClick={this.openRadeemModalHandler} style={{ textDecoration: "underline" }}>How to Redeem </span>
                              <span className="mx-2">|</span>
                              <span className="color-blue cursor-pointer" onClick={this.openVoucherDetailsModalHandler} style={{ textDecoration: "underline" }}> Voucher Details</span></p>
                          </div>
                          {this.state.isRadeemModalOpen && (
                            this.RadeemModal(this.state?.isRadeemModalOpen, this.closeRadeemModalHandler)
                          )}
                          {this.state.isVoucherDetailsModalOpen && (
                            this.VoucherDetailsModal(this.state?.isVoucherDetailsModalOpen, this.closeVoucherDetailsModelHandler)
                          )}
                        </div>
                      </>
                    }
                  </>

                )}
              </div>
            </>
          }
        </div>
      </React.Fragment>
    );
  };

  OrderStatusLabel = (order) => {
    if (order.fulfilled || order.status_code === 6 || order.status_code === 7)
      this.setState({ bgColor: "#11CE86" })
    else if ((!order.fulfilled && order.payment_failed) || order.status_code === 4)
      this.setState({ bgColor: "#ED2B2A" })
    else
      this.setState({ bgColor: "#ffc107" })
  }

  process = (paramsId) => {
    this.setState({ isFetchingVoucherCodes: true }, () => {
      voucherClientX
        .get(`v1/order/${paramsId}/code`)
        .then((response) => {
          let data = response.data.data;
          this.setState({
            vouchers: data,
            isFetchingVoucherCodes: false,
            voucherStatus: "success",
            currentVoucher: data[0]?.voucher_number,
            showRefreshStatus: false
          }, () => {
            this.filterCurrentVoucher(data[0]?.voucher_number)
          });
        })
        .catch((error) => {
          this.setState({
            isFetchingVoucherCodes: false,
            showRefreshStatus: true
          });
        });
    });
  };

  viewVoucher = () => {
    const {
      match: { params },
    } = this.props;

    this.process(params.id);
  };

  viewInvoice = (url) => {
    window.open(url);
  };

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  OrderMessages = (order) => {
    switch (order.status) {
      case 3:
        return (
          <div className="list-group-item d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
            <img src={wrpCoin} alt="wrpCoin" title="wrpCoin" />
            <p className="mb-0 ml-2">
              <strong>Congrats! You have earned {order.wrp} WINDS Coins for this transaction!</strong>
            </p>
          </div>
        )
      case 0:
      case 7:
        return (
          <div className="list-group-item d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
            <img src={wrpCoin} alt="wrpCoin" title="wrpCoin" />
            <p className="mb-0 ml-2">
              <strong>Great! You will soon earn {order.wrp} WINDS Coins for this transaction!</strong>
            </p>
          </div>
        )
      case 5:
        return (
          <div className="list-group-item d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
            <p className="mb-0 ml-2">
              <strong>Your refund will be credited in your original payment source within 3 to 5 working days</strong>
            </p>
          </div>
        )
      case 1:
      case 4:
        if (order.statusText === "Fulfillment Failed")
          return (
            <div className="list-group-item d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
              <p className="mb-0 ml-2">
                <strong>Any amount deducted will be refunded!</strong>
              </p>
            </div>
          )
        break;
      default:
        return (
          <></>
        )
    }
  }

  filterCurrentVoucher = (voucher_number) => {
    let currentSelectedVoucher = this.state.vouchers.filter(item =>
      item?.voucher_number === voucher_number
    )
    this.setState({ currentSelectedVoucher: currentSelectedVoucher })
  }

  OrderSummary = () => {
    const { order } = this.state
    return (
      <div className="row mb-3 mb-md-4">
        <div className="col-md-8 mx-auto">
          <div className="p-4" style={{ background: "#F4F4FF", borderRadius: "15px" }}>
            <div className="billpay-data position-relative">
              <h5 className="mb-3 font-weight-bold">Order Summary</h5>
              <hr className="w-100"></hr>
              <div className="d-flex justify-content-between">
                <p className="mb-1">Amount Paid</p>
                <p className="mb-1 font-weight-bold">₹ {(order.paid_amount - order.processing_fees).toFixed(2)}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-1">Processing Charge</p>
                <p className="mb-1 font-weight-bold">₹ {order.processing_fees?.toFixed(2)}</p>
              </div>
              <hr className="w-100"></hr>
              <div className="d-flex justify-content-between">
                <h5 className="font-weight-bold mb-1">Total</h5>
                <h5 className="mb-1 font-weight-bold">₹ {order.paid_amount?.toFixed(2)}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  Rewards = () => {
    const { order } = this.state
    return (
      <div className="row mb-3 mb-md-5">
        <div className="col-md-3 col-12 mx-auto">
          <button
            style={{ borderRadius: "30px" }}
            className={"btn billpay-order-btn w-100"}
            onClick={() => this.props.history.push("/rewards")}
          >
            <img src={ic_reward} alt="reward" title="reward" className="mr-2" width="30px" />
            Rewards
            {
              order?.pending_rewards > 0 &&
              <span className="ml-2" style={{ background: "#FF0000", padding: "2px 10px", borderRadius: "20px", minWidth: "30px" }}>{order?.pending_rewards.toString().padStart(2, '0')}</span>
            }
            <img src={ic_dropdown} alt="reward" title="reward" className="ml-2" width="10px" />
          </button>
        </div>
      </div>
    )
  }

  render() {
    const {
      order,
      canDownloadReceipt,
    } = this.state;
    return (
      order === null ?
        <this.Shimmer />
        :
        null !== order && (

          <React.Fragment>
            <div className="container">
              <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
                <div className="col-md-8 mx-auto">
                  <small>
                    <Link to="/">
                      <FormattedMessage
                        id="breadcrumbs.home"
                        defaultMessage="Home"
                      />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <Link to="/orders">
                      <FormattedMessage id="navbar.orders" />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <strong>
                      <FormattedMessage id="orders.order" />
                    </strong>
                  </small>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-8 mx-auto">
                  <div className="list-group">
                    <div className="list-group-item text-center" style={{ background: this.state.bgColor }}>
                      <p className="mb-0 text-white">
                        <PaymentStatusLabel order={order} />
                      </p>
                    </div>
                    <div className="list-group-item d-flex justify-content-between align-items-center">
                      <p className="d-flex align-items-center mb-0">
                        <span className="billpay-order-img-box">
                          <img src={ic_voucher} alt="voucher" title="voucher" width="75%" />
                        </span>
                        <p style={{ color: "#4354FF" }} className="h5 mb-0"><strong>Voucher</strong></p>
                      </p>
                      <Link to="/contact-us"><img src={supportImg} alt="supportImg" title="supportImg" /></Link>
                    </div>
                    <this.Voucher />
                    <div className="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <p className="mb-1">Voucher Amount</p>
                        <h5 className="d-flex align-items-center mb-0">
                          <img src={ic_rupee_symbol} alt="rupee_symbol" title="rupee_symbol" width="30px" className="mr-2" />
                          <strong>{order.amount?.toFixed(2)}</strong>
                        </h5>
                      </div>
                      {
                        (order.status_code === 3 || order.status_code === 7) &&
                        <span className="font-weight-bold d-flex align-items-center cursor-pointer" style={{ color: "#6372ff" }} onClick={() => this.props.history.push(`/voucher/${order?.retry_options?.voucher_slug}`)}>BUY AGAIN
                          <img src={arrowBlue} alt="arrowBlue" title="arrowBlue" width="20px" className="ml-2" />
                        </span>
                      }
                    </div>
                    <div className="list-group-item list-group-item-action">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <p className="mb-0">Order Status</p>
                          <OrderStatusLabel order={order} />
                        </div>
                        <div className="col-md-6 text-md-right">
                          <p className="mb-0">
                            <FormattedMessage id="orders.your-order-number-is" />{" "}
                            <strong>{order.order_number}</strong>
                          </p>

                          <p className="mb-0">{order.created_at}</p>
                        </div>
                      </div>
                    </div>
                    {this.state.vouchers[0]?.expiry_date &&
                      <div className="list-group-item ">
                        <p className="d-inline mb-0">Expiry Date{" "}-{" "}</p>
                        <p className="d-inline mb-0"><strong>{this.state.vouchers[0]?.expiry_date || "NA"}</strong></p>
                      </div>
                    }
                    {this.OrderMessages(order)}
                    {/* {
                      (order.status_code !== 6 && !order.payment_failed) &&
                      <div className="list-group-item list-group-item-action">
                        <p className="mb-0">
                          <strong>{order.wrp}</strong> WRP{" "}
                          {order.wrp_credited ? "credited" : "are pending"}
                        </p>
                      </div>
                    } */}
                  </div>
                </div>
              </div>
              <div className="row mb-3 mb-md-4">
                <div className="col-md-8 mx-auto">
                  <div className="display-btns-wrapper mt-2">
                    {/* add enlarge-btn to increase width */}
                    <div
                      className={classNames("btn-wrapper", {
                        "enlarge-btn": !order.invoice_url,
                        "disable-btn": !canDownloadReceipt,
                      })}
                    >
                      {canDownloadReceipt && (
                        <button
                          className={"btn mt-3 billpay-order-btn " + (order.invoice_url ? 'w-100' : 'btn-width-50 mx-auto')}
                          onClick={this.downloadReceipt}
                          disabled={this.state.receiptDownloading}
                        >
                          <img src={ic_download} alt="download" title="download" className="mr-2" width="15px" />
                          {this.state.receiptDownloading ?
                            "Downloading" :
                            <FormattedMessage
                              id="general.download-receipt"
                              defaultMessage="Download Receipt"
                            />
                          }
                        </button>
                      )}
                    </div>
                    <div
                      className={classNames("btn-wrapper", {
                        "enlarge-btn": !canDownloadReceipt,
                        "disable-btn": !order.invoice_url,
                      })}
                    >
                      {order.invoice_url && (
                        <button
                          className="btn mt-3 billpay-order-btn w-100"
                          onClick={() =>
                            this.viewInvoice(order.invoice_url)
                          }
                        >
                          <img src={ic_invoice} alt="invoice" title="invoice" className="mr-2" width="15px" />
                          <FormattedMessage
                            id="order.view-invoice"
                            defaultMessage="View Invoice"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" ref={this.loadRef}>
                <div className="col-md-8 mx-auto">
                  <div className="position-relative">
                    {order.won_reward === false ?
                      null :
                      order.rewards_cliamed === false ?
                        <div className="">
                          {claimNow(order)}
                          <Lottie className="celebration-lottie" animationData={SuccessBg} loop={!this.state.displayedFirstTime && this.state.show} />
                        </div>
                        : claimed(order)
                    }
                  </div>
                </div>
              </div>

              <this.OrderSummary />
              <this.Rewards />
            </div>
          </React.Fragment>
        )

    );

  }
}

function PaymentStatusLabel(props) {
  const status = props.order.status;
  switch (status) {
    case 0:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-pending"
            defaultMessage="is Pending"
          />
        </React.Fragment>
      );
    case 1:
    case 8:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-failed"
            defaultMessage="is Failed"
          />
        </React.Fragment>
      );
    case 6:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount?.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.has-been-refunded"
            defaultMessage="has been refunded!"
          />
        </React.Fragment>
      );
    case 4:
      return (
        <React.Fragment>
          Fulfillment of Rs. {" "}
          <strong>{props.order.paid_amount.toFixed(2)}</strong>{" "}
          has failed.
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-success"
            defaultMessage="is Successful"
          />
        </React.Fragment>
      );
  }
}
