import React, { PureComponent } from "react";
import TextField, { HelperText, Input } from '@material/react-text-field';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { handleCombinedErrors } from "../../utils/helper";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { Accordion, Card, Modal } from "react-bootstrap";
import update from "immutability-helper";
import profileClientX from "../../utils/api/profileClientX";
import voucherClientX from "../../utils/api/voucherClientX"
import billPayClientX from "../../utils/api/billPayClientX";
import InlineLoader from "../../components/InlineLoader";
import OtpValidationModal from "../../components/OtpValidationModal";
import Lottie from "lottie-react";
import { FirebaseContext } from "../../utils/firebase";
import "react-toastify/dist/ReactToastify.css";
import arrow from "../../assets/img/about/arrow.png"
import ic_debit_card from "../../assets/cart/ic_credit_card.png"
import ic_netbanking from "../../assets/cart/ic_netbanking.png"
import ic_wallet from "../../assets/cart/ic_wallet.png"
import ic_banks from "../../assets/cart/ic_banks.svg"
import ic_phonepe from "../../assets/billpay/ic_phonepe.png"
import ic_paytm from "../../assets/billpay/ic_paytm.png"
import ic_more from "../../assets/billpay/ic_more.png"
import ic_airtel from "../../assets/billpay/ic_airtel.png"
import ic_jiomoney from "../../assets/billpay/ic_jiomoney.png"
import ic_checkbox_active from "../../assets/ic_check_blue.svg";
import ic_checkbox_empty from "../../assets/ic_checkbox_empty.svg";
import ic_arrow_down from "../../assets/profile/ic_arrow.svg";
import ic_upi from "../../assets/billpay/ic_upi.svg"
import ic_all_results from "../../assets/billpay/ic_all_results.png"
import ic_search_results from "../../assets/billpay/ic_search_results.png"
import SearchImg from "../../assets/billpay/search.png"
import ic_clear_search from "../../assets/billpay/ic_clear_search.png"
import add_upi from "../../assets/cart/ic_add.png"
import close_upi from "../../assets/cart/ic_close.png"
import EmptyImage from "../../assets/voucher/ic_no_result_found.svg";
import ic_loader from "../../assets/cart/ic_loader.json"
import ic_success from "../../assets/cart/ic_success.json"
import ic_failed from "../../assets/cart/ic_failed.json"
import customerCommonClientX from "../../utils/api/customerCommonClientX";
import SweetAlert from 'react-bootstrap-sweetalert';

let startTime, interval, timer
class CartSummary extends PureComponent {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);

    this.state = {
      cart: [],
      payment_methods: [],
      load: false,
      billing_name: {
        value: "",
        isValid: false,
        error: "",
        isPristine: true,
      },
      billing_email: {
        value: "",
        isValid: false,
        error: "",
        isPristine: true,
      },
      billing_mobile: {
        value: "",
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_name: {
        value: "",
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_email: {
        value: "",
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_mobile: {
        value: "",
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_same_as_billing: false,
      formValid: false,
      paymentMode: null,
      selectedPaymentMethod: "",
      paymentGatewayForSelectedPaymentMethod: "",
      wallet_balance: null,
      wallet_enabled: 0,
      wallet_info: null,
      PG: 0,
      proceedPay: null,
      pageLoading: false,
      gc_wallet_balance: null,
      gc_wallet_enabled: 0,
      gc_wallet_info: null,
      isOtpValidationModalOpen: false,
      currentCartSegment: this.props.match.path.replace("/cart", "").slice(1),
      isBillingModalOpen: false,
      isBankListModalOpen: false,
      isConfirmModalOpen: false,
      isPaymentStatusModalOpen: false,
      bankListData: null,
      unchangedBankListData: null,
      bankSearchParams: "",
      userSelectedBank: null,
      additionalParameters: null,
      VPAEnabled: false,
      userEnteredVPA: "",
      vpaVerified: false,
      upiVpaSelected: false,
      canShowUpiVpaSelected: true,
      vpaVerificationErrorMessage: "",
      paymentStatusCheck: "PAYMENT_PENDING",
      order_id: null,
      minutes: 15,
      seconds: 0,
      redirectCount: 5,
      isVerifyingUpiID: false,
      verifiedVpaName: null,
      refund_policy: null
    };
  }

  componentDidMount() {
    this.setState({ pageLoading: true })

    profileClientX
      .get("v1/profile?billing_email=true")
      .then((response) => {
        const profileData = response.data.data;
        this.validateProfile(profileData)
        // this.setState({ pageLoading: false })
        this.getCartSummary()
      })
      .catch((error) => {
        this.setState({ pageLoading: false })
      });
  }

  openBillingModal = () => {
    this.setState({ isBillingModalOpen: true });
  };

  closeBillingModal = () => {
    this.setState({ isBillingModalOpen: false });
  };

  openBankListModal = () => {
    this.setState({ isBankListModalOpen: true });
  };

  closeBankListModal = () => {
    this.setState({ isBankListModalOpen: false });
  };

  openConfirmModal = () => {
    this.setState({ isConfirmModalOpen: true });
  };

  closeConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false, isPaymentStatusModalOpen: false, load: false, minutes: 15, seconds: 0 });
    clearInterval(interval);
    clearTimeout(timer)
  };

  openPaymentStatusModal = () => {
    this.state.selectedPaymentMethod === "upi" && this.state.paymentGatewayForSelectedPaymentMethod === "Phonepe" && this.setUPITimer() //timer for UPI
    this.setState({ isPaymentStatusModalOpen: true });
  };

  closePaymentStatusModal = () => {
    this.setState({ isPaymentStatusModalOpen: false, load: false, minutes: 15, seconds: 0 });
    clearInterval(interval);
    clearTimeout(timer)
  };

  setUPITimer = () => {
    startTime = new Date().getTime();
    timer = setTimeout(this.checkStatus, 20000); // Start after 20 seconds

    if (this.state.minutes === 0 && this.state.seconds === 0) {
      clearInterval(interval);
      clearTimeout(timer)
    } else {
      interval = setInterval(() => {
        // eslint-disable-next-line eqeqeq
        if (this.state.minutes == 0 && this.state.seconds == 0) {
          this.closePaymentStatusModal()
          this.viewOrderDetailPage()
        }
        if (this.state.seconds === 0)
          this.setState({ minutes: this.state.minutes - 1, seconds: 59 });
        else
          this.setState({ seconds: this.state.seconds - 1 })
      }, 1000);
    }
  }

  checkStatus = () => {
    // Perform your status check logic here
    this.state.paymentStatusCheck === "PAYMENT_PENDING" && this.getOrderStatus()

    const currentTime = new Date().getTime() - startTime;
    let nextInterval = 5000; // Default interval is 5 seconds

    if (currentTime >= 20000 && currentTime < 50000) {
      // Every 5 seconds for the next 30 seconds
      nextInterval = 5000;
    } else if (currentTime >= 50000 && currentTime < 110000) {
      // Every 10 seconds for the next 60 seconds
      nextInterval = 10000;
    } else if (currentTime >= 110000 && currentTime < 900000) {
      // Every 1 minute until timeout (15 minutes)
      nextInterval = 60000;
    } else {
      return;
    }

    // Schedule the next status check
    setTimeout(this.checkStatus, nextInterval);
  }

  openOtpValidationModal = () => {
    this.setState({ isOtpValidationModalOpen: true });
  };

  closeOtpValidationModal = () => {
    this.setState({ isOtpValidationModalOpen: false });
  };

  upiVpaControl = (event) => {
    this.setState({ VPAEnabled: !this.state.VPAEnabled })
  }

  handleUserVPA = (event) => {
    this.setState({ userEnteredVPA: event.target.value })
  }

  verifyUserVPA = () => {
    //verify UPI ID
    this.setState({ isVerifyingUpiID: true })
    let requestParams = {
      vpa: this.state.userEnteredVPA
    }
    customerCommonClientX
      .post(`v1/validate-vpa`, requestParams)
      .then((response) => {
        this.setState({ isVerifyingUpiID: false })
        let responseData = response?.data?.data
        if (responseData?.code === 'SUCCESS')
          this.setState({ vpaVerified: true, vpaVerificationErrorMessage: "", userEnteredVPA: responseData?.data?.vpa, verifiedVpaName: responseData?.data?.name })
        else
          this.setState({ vpaVerificationErrorMessage: 'Invalid UPI ID' })
      })
      .catch(error => {
        this.setState({ isVerifyingUpiID: false })
        let errorMessage = handleCombinedErrors(error.response?.data?.errors)
        this.setState({ vpaVerificationErrorMessage: errorMessage })
      })
  }

  selectUpiVpa = () => {
    this.setState({ upiVpaSelected: !this.state.upiVpaSelected })
  }

  clearUpiID = () => {
    this.setState({ vpaVerified: false, VPAEnabled: false, userEnteredVPA: "" })
  }

  getCartSummary = () => {
    let { cartAPIVersion, requestParams, cartAxiosClient } = this.handleCartSummaryAPIPayloads()

    cartAxiosClient
      .post(`${cartAPIVersion}/cart-details`, requestParams)
      .then((response) => {
        this.setState({
          pageLoading: false,
          cart: response.data.data.cart_data,
          wallet_balance: response.data.data.wallet_info.balance,
          wallet_info: response.data.data.wallet_info,
          cashback_details: response.data.data.cashback_details,
          gc_wallet_balance: response.data.data.gc_wallet_info.balance,
          gc_wallet_info: response.data.data.gc_wallet_info,
          refund_policy: response?.data?.data?.refund_policy
        });

        // eslint-disable-next-line array-callback-return
        response.data.data.cart_data.map(item => {
          if (item.key === "processing_fee") {
            this.setState({ PG: item.value })
          }
          if (item.key === "to_pay") {
            if (item.value === "₹ 0.00")
              this.setState({ proceedPay: null })
            else
              this.setState({ proceedPay: item.value })
          }
        })

        if (response.data.data.payment_methods !== null) {
          this.setState({ payment_methods: response.data.data.payment_methods })

          // eslint-disable-next-line array-callback-return
          response.data.data.payment_methods.map(item => {
            if (item.is_selected) {
              this.setState({
                selectedPaymentMethod: item.identifier,
                paymentGatewayForSelectedPaymentMethod: item.gateway,
                paymentMode: item.id,
                additionalParameters: item?.additional_parameters
              })
            }
            if (item?.identifier === "netbanking") {
              this.setState({ bankListData: item?.list_of_banks, unchangedBankListData: item?.list_of_banks })
            }
          })
        }
        else {
          this.setState({ payment_methods: [] })
        }

      })
      .catch((error) => {
        this.props.history.goBack();
      });
  }
//check here
  getOrderStatus = () => {
    customerCommonClientX
      .post(`v1/${this.state.payment_gateway_order_id}/payment-status/check`, { gateway_order_id: this.state.payment_gateway_order_id })
      .then((response) => {
        // console.log(response.data);
        this.setState({ load: false, paymentStatusCheck: response.data?.data?.code })
      })
      .catch((error) => {
        this.setState({ load: false })
      });
  }

  validateProfile = (profile) => {
    let email = "";
    if (profile?.email)
      email = profile?.email
    else if (profile?.billing_email)
      email = profile?.billing_email

    const { mobile, name } = profile;
    const newData = update(this.state, {
      billing_name: {
        value: { $set: name },
        isValid: { $set: false },
      },
      billing_email: {
        value: { $set: email },
        isValid: { $set: false },
      },
      billing_mobile: {
        value: { $set: mobile },
        isValid: { $set: false },
      },
      shipping_same_as_billing: { $set: true },
      formValid: { $set: true }
    });

    this.setState(newData, () => {
      this.validateField('billing_name', name)
    });
    this.setState(newData, () => {
      this.validateField('billing_email', email)
    });
    this.setState(newData, () => {
      this.validateField('billing_mobile', mobile)
    });

  }

  validateField(fieldName, value) {
    let state = this.state;

    switch (fieldName) {
      case "billing_name":
        state.billing_name.isValid = value.length >= 3;
        state.billing_name.error = state.billing_name?.isValid
          ? ""
          : "Please enter valid name";
        break;
      case "billing_mobile":
        state.billing_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.billing_mobile.error = state.billing_mobile?.isValid
          ? ""
          : " Please enter valid mobile number";
        break;
      case "billing_email":
        state.billing_email.isValid = ((value === "" || value === null || value === undefined) ? false : null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
        state.billing_email.error = state.billing_email?.isValid
          ? ""
          : "Please enter valid email";
        break;
      case "shipping_name":
        state.shipping_name.isValid = value.length >= 3;
        state.shipping_name.error = state.shipping_name?.isValid
          ? ""
          : "Please enter valid name";
        break;
      case "shipping_mobile":
        state.shipping_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.shipping_mobile.error = state.shipping_mobile?.isValid
          ? ""
          : "Please enter valid mobile number";
        break;
      case "shipping_email":
        state.shipping_email.isValid = ((value === "" || value === null || value === undefined) ? false : null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
        state.shipping_email.error = state.shipping_email?.isValid
          ? ""
          : "Please enter valid email";
        break;
      default:
        break;
    }

    this.setState({ state }, () => {
      this.validateForm()
    })
  }

  validateForm() {
    if (this.state.shipping_same_as_billing) {
      this.setState({
        formValid:
          this.state.billing_name?.isValid &&
          this.state.billing_email?.isValid &&
          this.state.billing_mobile?.isValid,
      });
      return;
    }

    this.setState({
      formValid:
        this.state.billing_name?.isValid &&
        this.state.billing_email?.isValid &&
        this.state.billing_mobile?.isValid &&
        this.state.shipping_name?.isValid &&
        this.state.shipping_email?.isValid &&
        this.state.shipping_mobile?.isValid,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newData = update(this.state, {
      [name]: {
        value: { $set: value },
        isPristine: { $set: false },
      },
    });

    this.setState(newData, () => {
      this.validateField(name, value);
    });
  };

  searchBank = (event) => {
    let bankSearchParams = event.target.value
    this.setState({ bankSearchParams: bankSearchParams })
    if (bankSearchParams?.length === 0) {
      this.setState({ bankListData: this.state.unchangedBankListData })
    }
    else {
      let searchResults = this.state.unchangedBankListData?.filter(item =>
        item.bank_name.toLowerCase().substring(0, bankSearchParams?.length) === bankSearchParams.toLowerCase()
      );
      this.setState({ bankListData: searchResults })
    }
  }

  clearBankSearch = () => {
    this.setState({ bankSearchParams: "" })
    this.setState({ bankListData: this.state.unchangedBankListData })
  }

  selectBankByUser = (userSelectedBank) => {
    this.closeBankListModal()
    this.setState({ userSelectedBank: userSelectedBank })
  }

  handleCartSummaryAPIPayloads = () => {
    let cartAPIVersion, requestParams, cartAxiosClient

    switch (this.state.currentCartSegment) {
      case "vouchers":
        cartAPIVersion = "v2"
        cartAxiosClient = voucherClientX
        requestParams = {
          denomination_id: this.props.denomination?.id,
          denomination_value: this.props.denomination?.value,
          quantity: this.props.quantity,
          wallet_enabled: this.state.wallet_enabled,
          payment_mode: this.state.paymentMode,
          gc_wallet_enabled: this.state.gc_wallet_enabled,
        }
        break;
      case "bill-pay":
        cartAPIVersion = "v3"
        cartAxiosClient = billPayClientX
        requestParams = {
          provider_id: this.props.billPayForm.provider_id,
          amount: this.props.billPayForm.amount,
          wallet_enabled: this.state.wallet_enabled,
          payment_mode: this.state.paymentMode,
          gc_wallet_enabled: this.state.gc_wallet_enabled,
        }
        break;
      default:
        break;
    }
    return { cartAPIVersion, cartAxiosClient, requestParams }
  }

  handleInitiateOrderAPIPayloads = () => {
    let initAPIVersion, requestParams, axiosClient, initiateOrderBaseURL, fulfilOrderBaseURL, fullfillAPIEndPoint

    switch (this.state.currentCartSegment) {
      case "vouchers":
        initAPIVersion = "v1"
        axiosClient = voucherClientX
        initiateOrderBaseURL = `${initAPIVersion}`
        fulfilOrderBaseURL = `${initAPIVersion}/orders`
        fullfillAPIEndPoint = `fulfill`

        requestParams = {
          denomination_id: this.props.denomination.id,
          denomination_value: this.props.denomination.value,
          quantity: this.props.quantity,
          billing_name: this.state.billing_name?.value,
          billing_email: this.state.billing_email?.value,
          billing_mobile: this.state.billing_mobile?.value,
          shipping_name: this.state.shipping_name?.value,
          shipping_email: this.state.shipping_email?.value,
          shipping_mobile: this.state.shipping_mobile?.value,
          shipping_same_as_billing: this.state.shipping_same_as_billing ? 1 : 0,
          wallet_enabled: this.state.wallet_enabled,
          payment_mode: this.state.paymentMode,
          gc_wallet_enabled: this.state.gc_wallet_enabled

        }
        if (this.props.giftDetails.gifting_name) {
          requestParams['gifting_name'] = this.props.giftDetails.gifting_name
          requestParams['gifting_mobile'] = this.props.giftDetails.gifting_mobile
          requestParams['gifting_email'] = this.props.giftDetails.gifting_email
          requestParams['gifting_message'] = this.props.giftDetails.gifting_message
        }
        break;
      case "bill-pay":
        initAPIVersion = this.props.billpayAPIVersion
        axiosClient = billPayClientX
        initiateOrderBaseURL = `${initAPIVersion}`
        fulfilOrderBaseURL = `${initAPIVersion}/orders`
        fullfillAPIEndPoint = `fulfill`

        requestParams = this.props.billPayForm
        requestParams.payment_mode = this.state.paymentMode
        requestParams.billing_email = this.state.billing_email?.value
        requestParams.wallet_enabled = this.state.wallet_enabled
        requestParams.gc_wallet_enabled = this.state.gc_wallet_enabled
        break;
      default:
        break;
    }

    return { axiosClient, initiateOrderBaseURL, fulfilOrderBaseURL, fullfillAPIEndPoint, requestParams }
  }

  handleCartdataLists = () => {
    let image_url, name, amount, form
    if (this.state.currentCartSegment === "vouchers") {
      image_url = this.props.voucher?.image_url;
      name = this.props.voucher?.name
      amount = this.props.denomination?.value
    }
    else {
      image_url = this.props.billpayData?.web_image_url;
      name = this.props.billpayData?.name
      amount = this.props.billPayForm?.amount
      form = this.props.billPayForm
    }
    return { image_url, name, amount, form }
  }

  handleWallet = () => {
    this.setState({ wallet_enabled: !this.state.wallet_enabled, selectedPaymentMethod: "wallet" }, () => {
      this.getCartSummary()
    })
  }

  handleGCWallet = (e) => {
    this.setState({ gc_wallet_enabled: !this.state.gc_wallet_enabled, selectedPaymentMethod: "gc_wallet" }, () => {
      this.getCartSummary()
    })
  }

  handlePayment = (id) => {
    this.setState({ paymentMode: id },
      () => {
        this.getCartSummary()
      })
  }

  onShippingCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    this.setState(
      {
        shipping_same_as_billing: isChecked,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleProceedToPay = () => {
    if (this.state.paymentGatewayForSelectedPaymentMethod === "Phonepe") {
      if (this.state.selectedPaymentMethod === "upi" && (!this.state.vpaVerified && !this.state.upiVpaSelected))
        return toast.error("Enter your UPI ID")
      if (this.state.selectedPaymentMethod === "netbanking" && !this.state.userSelectedBank)
        return toast.error("Please select bank")
    }
    if (this.state.formValid)
      this.onInitOtpValidation()
    else
      this.openBillingModal()
  }

  onInitOtpValidation = () => {
    if (this.state.wallet_enabled || this.state.gc_wallet_enabled)
      this.openOtpValidationModal()
    else
      this.onInitOrder()
  }

  onInitOrder = () => {
    const { axiosClient, initiateOrderBaseURL, fulfilOrderBaseURL, fullfillAPIEndPoint, requestParams } = this.handleInitiateOrderAPIPayloads()

    if (this.state.additional_parameters !== null) {
      let additional_parameters = this.state.additionalParameters
      if (additional_parameters?.type === "NET_BANKING")
        additional_parameters.bankId = this.state.userSelectedBank?.bank_id || ""
      else if (additional_parameters?.type === "UPI_INTENT" || additional_parameters?.type === "UPI_COLLECT") {
        additional_parameters = {
          type: "UPI_COLLECT",
          vpa: this.state.userEnteredVPA || ""
        }
      }
      requestParams.payment_instrument = additional_parameters
    }
    // return

    this.setState({ load: true, paymentStatusCheck: "PAYMENT_PENDING" })
    axiosClient
      .post(`${initiateOrderBaseURL}/initiate-order`, requestParams)
      .then((response) => {
        this.setState({
          payment_gateway_order_id: response.data?.data?.payment_gateway_order_id,
          order_id: response.data.data.order_id
        })
        if (response.data.data.proceed_to_gateway === false) {
          this.setState({ load: true })
          this.openPaymentStatusModal()
          axiosClient
            .post(`${fulfilOrderBaseURL}/${response.data.data.order_id}/${fullfillAPIEndPoint}`, {
              payment_gateway_order_id: null,
              razorpay_order_id: null,
            })
            .then((cancelresponse) => {
              this.setState({ load: false, paymentStatusCheck: "PAYMENT_SUCCESS" })
            }).catch(error => {
              this.setState({ load: false, paymentStatusCheck: "PAYMENT_FAILED" })
            })
        }
        else {
          if (response.data.data.razorpay_key === null || response.data.data.razorpay_key === "") {
            this.setState({ load: false })
            toast.error(
              "Something went wrong. Please try again later",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
          else {
            if (response.data?.data?.proceed_with_gateway === "Phonepe")
              this.handlePhonepeIntent(axiosClient, fullfillAPIEndPoint, fulfilOrderBaseURL, response.data.data)
            else
              this.handleRazorpayIntent(response.data.data.razorpay_key, response.data.data.payment_gateway_order_id, axiosClient, fullfillAPIEndPoint, fulfilOrderBaseURL, response.data.data.order_id)
          }
        }
      })
      .catch((error) => {
        this.setState({ load: false })
      });
  };
//check here
  handleRazorpayIntent = (razorpay_key, payment_gateway_order_id, axiosClient, fullfillAPIEndPoint, fulfilOrderBaseURL, order_id) => {
    let that = this
    new window.Razorpay({
      key: razorpay_key,
      order_id: payment_gateway_order_id,
      "theme": {
        "hide_topbar": true
      },
      "prefill": {
        "name": this.state.billing_name?.value,
        "email": this.state.billing_email?.value,
        "contact": this.state.billing_mobile?.value,
        "method": this.state.selectedPaymentMethod,
      },
      handler: function (pgResponse) {
        that.openPaymentStatusModal()
        axiosClient
          .post(`${fulfilOrderBaseURL}/${order_id}/${fullfillAPIEndPoint}`, {
            payment_gateway_order_id: payment_gateway_order_id,
            razorpay_order_id: pgResponse.razorpay_order_id,
            razorpay_payment_id: pgResponse.razorpay_payment_id,
            razorpay_signature: pgResponse.razorpay_signature,
          })
          .then((response) => {
            that.openPaymentStatusModal()
            that.setState({ paymentStatusCheck: "PAYMENT_SUCCESS", load: false })
          })
          .catch((error) => {
            that.viewOrderPage();
            // that.openPaymentStatusModal()
            // that.setState({ paymentStatusCheck: "PAYMENT_FAILED", load: false })
          });
      },
      "modal": {
        "ondismiss": function () {
          that.openPaymentStatusModal()
          axiosClient
            .post(`${fulfilOrderBaseURL}/${order_id}/${fullfillAPIEndPoint}`, {
              payment_gateway_order_id: payment_gateway_order_id,
              razorpay_order_id: payment_gateway_order_id,
              payment_failed: 1
            })
            .then((cancelresponse) => {
              that.openPaymentStatusModal()
              that.setState({ paymentStatusCheck: "PAYMENT_FAILED", load: false })
            }).catch(error => {
              that.openPaymentStatusModal()
              that.setState({ paymentStatusCheck: "PAYMENT_FAILED", load: false })
            })
        }
      }
    }).open();
  }

  handlePhonepeIntent = (axiosClient, fullfillAPIEndPoint, fulfilOrderBaseURL, responseData) => {

    let url = responseData?.phonepe_checkout?.redirectInfo?.url

    //for upi payments - open processing modal
    if (this.state.selectedPaymentMethod === "upi") {
      this.openPaymentStatusModal()
    }

    // for other payments - check browser pop-ups permission and handle
    if (url) {
      const paymentWindow = window.open(url, 'Secure Payment');
      if (paymentWindow == null || typeof (paymentWindow) == 'undefined') {
        alert('Please disable your pop-up blocker to continue with the payment process.');
        this.setState({ load: false })
      } else {
        this.openPaymentStatusModal()
        const timer = setInterval(() => {
          if (paymentWindow?.closed) {
            clearInterval(timer);
            this.getOrderStatus()
          }
        }, 4000);
      }
    }
  }

  viewOrderDetailPage = () => {
    this.props.history.push(`/${this.state.currentCartSegment}/orders/${this.state.order_id}`);
  }

  viewOrderPage = () => {
    this.props.history.push(`/orders`);
  }

  render() {
    // console.log('this.state.selectedPaymentMethod,  this.state.paymentGatewayForSelectedPaymentMethod, paymentStatusCheck', this.state.selectedPaymentMethod,  this.state.paymentGatewayForSelectedPaymentMethod, this.state.paymentStatusCheck);
   
    return (
      this.state.pageLoading ?
        <this.Shimmer /> :
        <div style={{ background: "#F5F2FC" }}>
          <div className="container">
            {/* <div className="row ">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="col-12">
                  <small>
                    <Link to="/">
                      <FormattedMessage
                        id="breadcrumbs.home"
                        defaultMessage="Home"
                      />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <Link to="/vouchers">
                      <FormattedMessage
                        id="home.vouchers"
                        defaultMessage="Vouchers"
                      />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <strong>
                      <FormattedMessage
                        id="vouchers.buy-vouchers"
                        defaultMessage="Buy Voucher"
                      />
                    </strong>
                  </small>
                </div>
              </div>
            </div>
          </div> */}
            <div className="row pt-md-5 pt-4">
              <div className="col-12">
                <div className="row mb-5">
                  <div className="col-12 col-md-6">

                    {/* Bill Details component for mobile viewport */}
                    {window.innerWidth <= 767 && <this.CartlistData />}

                    {/* WINDS Wallet & Voucher */}
                    {(this.state?.wallet_info?.can_show_wallet || this.state?.gc_wallet_info?.can_show_wallet) &&
                      <div className="mb-md-5 mb-4">
                        <h3 className="choose d-flex align-items-center">
                          <div className="cart-title-rect mr-3"></div>
                          <span>WINDS Wallet & Voucher</span>
                        </h3>
                        <this.WalletControl />
                        {this.GCWalletControl()}
                      </div>
                    }
                    

                    {/* Pay using Apps */}
                    <this.PayUsingApps />

                    {/* More Payment Methods */}
                    <div className="mt-md-5 mt-4">
                      {this.state.payment_methods && this.state.payment_methods.length > 0 &&
                        <>
                          <h3 className="choose d-flex align-items-center">
                            <div className="cart-title-rect mr-3"></div>
                            <span><FormattedMessage id="payment-options" defaultMessage="More Payment Options" /></span>
                          </h3>
                          <this.PaymentMethods />
                        </>
                      }
                    </div>

                    {/* Refund Policy for desktop viewport */}
                    {window.innerWidth > 767 && <this.RefundPolicy />}
                  </div>

                  <div className="col-12 col-md-6">
                    <h3 className="choose d-none d-md-block" style={{ visibility: "hidden" }}>{"dummy"}</h3>

                    {/* Bill Details component for desktop viewport */}
                    {window.innerWidth > 767 && <this.CartlistData />}

                    {/* Billing and Shipping Details */}
                    <this.BillingModal />

                    <this.ProceedToPay />

                    {/* Refund Policy for mobile viewport */}
                    {window.innerWidth <= 767 && <this.RefundPolicy />}

                  </div>
                </div>
              </div>
            </div>
            {this.state.isOtpValidationModalOpen && (
              <OtpValidationModal
                isOtpValidationModalOpen={this.state.isOtpValidationModalOpen}
                closeOtpValidationModal={this.closeOtpValidationModal}
                onVerifyOTP={(otpStatus) => otpStatus && this.onInitOrder()}
              />
            )}
            {this.state.isPaymentStatusModalOpen && (
              <this.PaymentStatusModal />
            )}
            {this.state.isConfirmModalOpen && (
              <this.ConfirmDialog />
            )}
          </div>
        </div>
    );
  }

  Shimmer = (props) => {
    return (
      <div style={{ background: "#F5F2FC" }}>
        <div className="container py-3 py-md-5">
          <div className="row">
            <div className="col-md-6">
              <div className="box shine box-voucher-detail-logo mb-2"></div>
              <div className="box shine w-100 mb-2" style={{ height: "60px" }}></div>
              <div className="box shine box-voucher-detail-logo"></div>
            </div>
            <div className="col-md-6 pt-4 pt-md-0">
              <div className="box shine w-100" style={{ height: "40px" }}></div>
              <div className="box shine w-100" style={{ height: "300px" }}></div>
              <div className="box shine w-100" style={{ height: "38px" }}></div>
              <div className="box shine w-100" style={{ height: "70px" }}></div>
              <div className="box shine w-100" style={{ height: "70px" }}></div>
              <div className="box shine w-100" style={{ height: "70px" }}></div>
              <div className="box shine w-100" style={{ height: "70px" }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  BillingDetails = (props) => {
    return (
      <div className="mb-3">
        <div className="mb-3">
          <h5 className="font-proxima-nova-bold mb-md-4 mb-2">
            <FormattedMessage id="vouchers.billing-details" />
          </h5>
        </div>
        <div className="">
          <div className="form-group mb-4">
            <TextField
              className="w-100"
              outlined
              id="outlined-basic"
              label={<FormattedMessage id="vouchers.billing-name" />}
              helperText={!this.state.billing_name?.isValid && <HelperText className="text-danger">{this.state.billing_name?.error}</HelperText>}
            ><Input
                isValid={this.state.billing_name?.isValid}
                value={this.state.billing_name?.value}
                name="billing_name"
                onChange={(event) => {
                  // console.log(event.target);
                  this.handleUserInput(event);
                }}
              />
            </TextField>
          </div>
          <div className="form-group mb-4">
            <TextField
              className="w-100"
              outlined
              id="outlined-basic"
              label={<FormattedMessage id="vouchers.billing-mobile" />}
              helperText={!this.state.billing_mobile?.isValid && <HelperText className="text-danger">{this.state.billing_mobile?.error}</HelperText>}
            ><Input
                isValid={this.state.billing_mobile?.isValid}
                value={this.state.billing_mobile?.value}
                name="billing_mobile"
                onChange={(event) => {
                  // console.log(event.target);
                  this.handleUserInput(event);
                }}
              />
            </TextField>
          </div>
          <div className="form-group mb-4">
            <TextField
              className="w-100"
              outlined
              id="outlined-basic"
              label={<FormattedMessage id="vouchers.billing-email" />}
              helperText={!this.state.billing_email?.isValid && <HelperText className="text-danger">{this.state.billing_email?.error}</HelperText>}
            ><Input
                isValid={this.state.billing_email?.isValid}
                value={this.state.billing_email?.value}
                name="billing_email"
                onChange={(event) => this.handleUserInput(event)}
              />
            </TextField>
          </div>
        </div>
      </div>
    );
  };

  ShippingDetails = (props) => {
    return (
      <div className="mb-3">
        <div className="row flex-column align-items-center">
          <div className="col-12 col-md-12">
            <h5 className="font-proxima-nova-bold mb-0">
              <FormattedMessage id="vouchers.shipping-details" />
            </h5>
          </div>
          <div className="col-12 col-md-12 mb-3">
            <div className="form-check form-check-inline cursor-pointer">
              <input
                id="same"
                className="form-check-input cursor-pointer"
                type="checkbox"
                checked={this.state.shipping_same_as_billing}
                onChange={this.onShippingCheckboxChange}
              />
              <label className="form-check-label cursor-pointer" htmlFor="same">
                <FormattedMessage id="vouchers.same-as-billing-details" />
              </label>
            </div>
          </div>

        </div>

        {!this.state.shipping_same_as_billing && (
          <div className="">
            <div className="form-group">
              <TextField
                className="w-100"
                outlined
                id="outlined-basic"
                label={<FormattedMessage id="vouchers.shipping-name" />}
                helperText={!this.state.shipping_name?.isValid && <HelperText className="text-danger">{this.state.shipping_name?.error}</HelperText>}
              ><Input
                  isValid={!this.state.shipping_name?.isPristine && this.state.shipping_name?.isValid}
                  value={this.state.shipping_name?.value}
                  name="shipping_name"
                  onChange={(event) => this.handleUserInput(event)}
                />
              </TextField>
            </div>
            <div className="form-group">
              <TextField
                className="w-100"
                outlined
                id="outlined-basic"
                label={<FormattedMessage id="vouchers.shipping-mobile" />}
                helperText={!this.state.shipping_mobile?.isValid && <HelperText className="text-danger">{this.state.shipping_mobile?.error}</HelperText>}
              ><Input
                  isValid={!this.state.shipping_mobile?.isPristine && this.state.shipping_mobile?.isValid}
                  value={this.state.shipping_mobile?.value}
                  name="shipping_mobile"
                  onChange={(event) => this.handleUserInput(event)}
                />
              </TextField>
            </div>
            <div className="form-group">
              <TextField
                className="w-100"
                outlined
                id="outlined-basic"
                label={<FormattedMessage id="vouchers.shipping-email" />}
                helperText={!this.state.shipping_email?.isValid && <HelperText className="text-danger">{this.state.shipping_email?.error}</HelperText>}
              ><Input
                  isValid={!this.state.shipping_email?.isPristine && this.state.shipping_email?.isValid}
                  value={this.state.shipping_email?.value}
                  name="shipping_email"
                  onChange={(event) => this.handleUserInput(event)}
                />
              </TextField>
            </div>
          </div>
        )}
      </div>
    );
  };

  Cashback = (props) => {
    const { cashback_details } = this.state;
    return (
      cashback_details?.can_display &&
      <div className="voucher-cashback mb-4 mt-2">
        <div className="row">
          <div className="col-12">
            <h6 className="d-flex align-items-center justify-content-center m-0">
              {/* <img src={ic_save} width="25px" style={{ marginRight: "10px" }} alt="cashback" /> */}
              {cashback_details?.display_string}
            </h6>
          </div>
        </div>
      </div>
    )
  }

  BillingModal = (props) => {
    const { isBillingModalOpen } = this.state;
    return (
      <React.Fragment>
        <div
          className="py-2 px-3 d-flex justify-content-between align-items-center cursor-pointer mb-3"
          style={{ background: "radial-gradient(circle, #F9CEDA 0.02%, #FDF3F4 100%)", borderRadius: "5px" }}
          onClick={() => this.openBillingModal()}>
          <span className="font-weight-bold">Billing and Shipping Details</span>
          <img src={arrow} alt="img" width="15px" />
        </div>
        <Modal
          show={isBillingModalOpen}
          className="font-proxima-nova"
          centered
          scrollable
          style={{ minHeight: "500px" }}
        >
          <Modal.Body className="px-4 pt-4 pb-0" >
            <button className="btn-close-card-type" style={{ zIndex: 999, background: "#eaf6ff" }} onClick={this.closeBillingModal}>
              <i className="fa fa-close"></i>
            </button>
            <div className="d-flex flex-column justify-content-between w-100 " >
              {this.BillingDetails()}
              {this.ShippingDetails()}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center w-100 py-2" style={{ "position": "sticky", "bottom": 0, "background": "white" }}>
              <button
                className={`animated-btn py-1 px-5 ${!this.state.formValid ? 'animated-btn-disabled' : ""}`}
                style={{ width: "auto" }}
                onClick={() => {
                  // this.handleProceedToPay()
                  this.closeBillingModal()
                }}
                disabled={!this.state.formValid}
              >CONFIRM</button>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }

  BankListModal = (props) => {
    const { isBankListModalOpen } = this.state;
    return (
      <React.Fragment>
        <div className="d-flex justify-content-start align-items-center cursor-pointer ml-3"
          onClick={() => this.openBankListModal()}
        >
          <p className="font-weight-bold color-blue m-0">{this.state.userSelectedBank ? this.state.userSelectedBank?.bank_name : "Choose the Bank"}</p>
          <img className="ml-md-2 ml-1" src={ic_arrow_down} style={{ rotate: "90deg" }} alt="img" width="5px" />
        </div>
        <Modal
          show={isBankListModalOpen}
          className="font-proxima-nova"
          centered
          scrollable
          style={{ minHeight: "500px" }}
        >
          <Modal.Body className="px-4 pt-4 pb-0" >
            <button className="btn-close-card-type" style={{ zIndex: 999, background: "#eaf6ff" }} onClick={this.closeBankListModal}>
              <i className="fa fa-close"></i>
            </button>
            <h5 className="font-weight-bold">Choose Bank</h5>
            <div className="d-flex flex-column justify-content-between w-100 " >
              <div className="position-relative mt-md-0 mt-2 mb-3">
                {
                  this.state.bankSearchParams === "" ?
                    <img className="provider-search-img" src={SearchImg} alt="search" /> :
                    <img className="provider-search-img cursor-pointer" width="16px" src={ic_clear_search} alt="clear" onClick={() => this.clearBankSearch()} />
                }
                <input className="biller-search w-100 " type="text" placeholder="Search by bank name" value={this.state.searchParams} onChange={(e) => this.searchBank(e)} />
              </div>
              <div className="mb-0">
                {this.state?.bankListData && this.state.bankSearchParams?.length === 0 &&
                  <>
                    <div className="d-flex align-items-center mb-2 mt-2">
                      <img className="mr-2" src={ic_all_results} alt="img" width="20px" />
                      <span style={{ color: "#4354FF" }}>Popular Banks</span>
                    </div>
                    <div className="d-flex align-items-center py-2 mb-4 cursor-pointer justify-content-start" style={{ width: "100%", overflowX: "auto" }}>
                      {/* eslint-disable-next-line array-callback-return */}
                      {this.state?.bankListData && this.state?.bankListData?.map((item, index) => {
                        if (item?.is_popular) return (
                          <div className="d-flex flex-column align-items-center align-self-start p-1" key={item.bank_id} style={{ maxWidth: "120px", minWidth: "120px" }} onClick={() => this.selectBankByUser(item)}>
                            <div className="billpay-providers-img-box" style={{ border: "none", margin: 0 }}>
                              <img src={item?.image_url} alt="img" width="75%" />
                            </div>
                            <span style={{ lineHeight: "1rem", textAlign: "center" }}><small>{item.bank_name}</small></span>
                          </div>
                        );
                      })}
                    </div>
                  </>
                }
                {
                  this.state.bankSearchParams?.length === 0 ?
                    <div className="d-flex align-items-center mb-2 mt-4">
                      <img className="mr-2" src={ic_banks} alt="img" width="20px" />
                      <span style={{ color: "#4354FF" }}>All Banks</span>
                    </div> :
                    <div className="d-flex align-items-center mt-2">
                      <img className="mr-2" src={ic_search_results} alt="img" width="20px" />
                      <span style={{ color: "#4354FF" }}>Search Results</span>
                    </div>
                }
                {this.state?.bankListData?.length > 0 ?
                  <div className="provider-scroll-list">
                    {this.state?.bankListData && this.state?.bankListData?.map((item, index) => {
                      return (
                        <div className="d-flex align-items-center py-2 border-bottom cursor-pointer justify-content-between" key={item?.bank_id} onClick={() => this.selectBankByUser(item)}>
                          <div className="d-flex align-items-center">
                            <div className="billpay-providers-img-box" style={{ border: "none" }}>
                              <img src={item?.image_url} alt="img" width="75%" />
                            </div>
                            <span>{item.bank_name}</span>
                          </div>
                          <img src={ic_arrow_down} alt="img" width="8px" />
                        </div>
                      );
                    })}
                  </div> :
                  <div className="d-flex justify-content-center my-4">
                    <img src={EmptyImage} width="70%" alt="empty" />
                  </div>
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }

  PaymentStatusModal = (props) => {
    const { isPaymentStatusModalOpen } = this.state;
    return (
      <React.Fragment>
        <div className="py-2 px-3 d-flex justify-content-between align-items-center cursor-pointer mb-3"
          style={{ background: "radial-gradient(circle, #F9CEDA 0.02%, #FDF3F4 100%)", borderRadius: "5px" }}
          onClick={() => this.openPaymentStatusModal()}>
          <img src={arrow} alt="img" width="15px" />
        </div>
        <Modal
          show={isPaymentStatusModalOpen}
          className="font-proxima-nova"
          centered
          scrollable
          style={{ minHeight: "500px" }}
        >
          <Modal.Body className="p-4" >
            {this.state.paymentStatusCheck === "PAYMENT_PENDING" &&
              <button className="btn-close-card-type" style={{ zIndex: 999, background: "#eaf6ff" }} onClick={() => {
                if (this.state.paymentStatusCheck === "PAYMENT_PENDING")
                  this.openConfirmModal()
                else
                  this.setState({ isPaymentStatusModalOpen: false })
              }}>
                <i className="fa fa-close"></i>
              </button>
            }
            {this.state.paymentStatusCheck === "PAYMENT_SUCCESS" ?
              this.PaymentSuccessContent() : 
              (this.state.paymentStatusCheck === "PAYMENT_FAILED" || this.state.paymentStatusCheck === "PAYMENT_ERROR") ?
                this.PaymentFailedContent() : 
                (this.state.paymentStatusCheck === "PAYMENT_PENDING" && this.state.selectedPaymentMethod === "upi" && this.state.paymentGatewayForSelectedPaymentMethod === "Phonepe") ?
                  this.PaymentProcessingWithTimerContent() : this.PaymentProcessingContent()
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }

  ConfirmDialog = () => {
    return (
      <SweetAlert
        custom
        showCancel
        // showCloseButton
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        onConfirm={this.closeConfirmModal}
        onCancel={() => { this.setState({ isConfirmModalOpen: false }) }}
      >
        <h5>Are you sure you want to cancel the payment?</h5>
      </SweetAlert>
    )
  }

  PaymentProcessingContent = () => {
    return (
      <div className="d-flex flex-column justify-content-between align-items-center w-100 " >
        <Lottie animationData={ic_loader} loop={true} width="50" style={{ width: "40%" }} />
        <span className="h4 mt-3 font-weight-bold">Payment Processing</span>
        <span className="h5 mt-2">Waiting for Response!</span>
        <p className="mt-4 text-center" style={{ color: "#6D6D6D" }}>Kindly avoid closing or forcefully terminating the application</p>
      </div>
    )
  }

  PaymentProcessingWithTimerContent = () => {
    return (
      <div className="d-flex flex-column justify-content-between align-items-center w-100 " >
        <Lottie animationData={ic_loader} loop={true} width="50" style={{ width: "40%" }} />
        <span className="h4 mt-3 font-weight-bold">Payment Processing</span>
        <span className="h5 mt-2">Waiting for Response!</span>
        <p className="mt-4 text-center" style={{ color: "#6D6D6D" }}>Kindly avoid closing or forcefully terminating the application</p>
        <span className="h4 mt-4 font-weight-bold">{this.state.minutes.toString().padStart(2, '0')}:{this.state.seconds.toString().padStart(2, '0')}</span>
        <span className="h5 mt-">mins remaining</span>
      </div>
    )
  }

  redirectToOrderDetail = () => {
    let interval
    if (this.state.redirectCount === 0) {
      clearInterval(interval);
      this.viewOrderDetailPage()
    } else {
      let count = this.state.redirectCount
      interval = setInterval(() => {
        this.setState({ redirectCount: count - 1 })
      }, 1000);
    }
  }

  PaymentSuccessContent = () => {
    this.redirectToOrderDetail()
    return (
      <div className="d-flex flex-column justify-content-between align-items-center w-100 " >
        <Lottie animationData={ic_success} loop={false} width="50" style={{ width: "40%" }} />
        <span className="h4 mt-3 mb-5 font-weight-bold">Your Payment has been Successful!</span>
        <div className="d-flex flex-column justify-content-center align-items-center w-100 py-2" style={{ "position": "sticky", "bottom": 0, "background": "white" }}>
          <button
            className='animated-btn py-1 px-5 mb-3'
            style={{ height: "50px", width: "auto" }}
            onClick={() => this.viewOrderDetailPage()}
          >View Order Details</button>
          <Link to="/" >
            <span className="color-blue">Go back to Home{">"}</span>
          </Link>
        </div>
      </div>
    )
  }

  PaymentFailedContent = () => {
    this.redirectToOrderDetail()
    return (
      <div className="d-flex flex-column justify-content-between align-items-center w-100 " >
        <Lottie animationData={ic_failed} loop={false} width="50" style={{ width: "40%" }} />
        <span className="h4 mt-3 mb-5 font-weight-bold">Your payment has been failed!</span>
        <div className="d-flex flex-column justify-content-center align-items-center w-100 py-2" style={{ "position": "sticky", "bottom": 0, "background": "white" }}>
          <button
            className='animated-btn py-1 px-5 mb-3'
            style={{ height: "50px", width: "auto" }}
            onClick={() => this.closePaymentStatusModal()}
          >Retry Again</button>
          <Link to="/" >
            <span className="color-blue">Go back to Home{">"}</span>
          </Link>
        </div>
      </div>
    )
  }

  WalletControl = () => {
    return (
      <>
        <div className={`card-body payment-card mb-3 cursor-pointer ${this.state?.wallet_info?.can_show_wallet ? "" : "wallet-disable"}`} onClick={() => this.handleWallet()}>
          <div className="row align-items-center">
            <div className="col-8 d-flex">
              <img src={this.state.wallet_enabled ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
              <div className="payment-card-top d-flex flex-column align-items-start m-0 ml-md-4 ml-3 mb-1">
                <h3 className="m-0">
                  <FormattedMessage id="wallet.wallet-balance" defaultMessage="Wallet Balance" />
                </h3>
                <p className="text-red m-0"><small>{this.state?.wallet_info?.min_balance_text}</small></p>
              </div>
            </div>
            <div className="col-4">
              <div className="payment-card-top d-flex flex-row justify-content-end align-items-center">
                <h3 className="m-0 color-blue">₹ {this.state.wallet_balance}</h3>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  GCWalletControl = () => {
    if (this.state.gc_wallet_info?.can_show_wallet)
      return (
        <>
          <div className={`card-body payment-card mt-3 mb-2 cursor-pointer ${this.state?.gc_wallet_info?.can_show_wallet ? "" : "wallet-disable"}`} onClick={() => this.handleGCWallet()}>
            <div className="row align-items-center">
              <div className="col-9 d-flex">
                <img src={this.state.gc_wallet_enabled ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                <div className="payment-card-top d-flex flex-row align-items-start m-0 ml-md-4 ml-3 mb-1">
                  <h3 className="m-0">
                    <FormattedMessage id="wallet.gc_wallet-balance" defaultMessage="WINDS E-Gift Voucher" />
                  </h3>
                </div>
              </div>
              <div className="col-3">
                <div className="payment-card-top d-flex flex-row justify-content-end align-items-center m-0">
                  <h3 className="m-0 color-blue ">₹ {this.state.gc_wallet_balance}</h3>
                </div>
              </div>
            </div>
          </div>
        </>
      )
  }

  PayUsingApps = (props) => {
    return (
      // eslint-disable-next-line array-callback-return
      this.state.payment_methods.map((item, index) => {
        if (item.identifier === "upi" && item?.gateway === 'Phonepe')
          return (
            <div className="" key="upi">
              <h3 className="choose d-flex align-items-center">
                <div className="cart-title-rect mr-3"></div>
                <span>Pay using UPI</span>
              </h3>
              <div id={"b" + item.id} className={"card-body payment-card mb-3 cursor-pointer " + (item.is_selected ? 'select-border' : '')} onClick={() => this.handlePayment(item.id)}>
                <div className="row align-items-center justify-content-between">
                  <div className="col-1 col-md-1">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                  <div className="col-10 col-md-11 pl-md-4 pl-0">
                    <div className="payment-card-top d-flex flex-row justify-content-between align-items-center">
                      <h3 className="m-0">{item?.name}</h3>
                      <div className="payment-card-bottom d-flex flex-row align-items-center">
                        <p className="m-0">{item.display_string}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 mt-md-2">
                  <div className="col-12">
                    {/* {this.state.canShowUpiVpaSelected &&
                      <div className={`d-flex justify-content-between w-100 p-md-3 p-2 mb-2 mt-2 border-top border-bottom ${this.state.upiVpaSelected ? "upi-select" : ""}`} style={{ borderRadius: "5px" }}
                        onClick={() => {
                          this.selectUpiVpa()
                        }}>
                        <div className="d-flex">
                          <img src={ic_checkbox_active} alt="active" width="18px" className="mr-2" />
                          <span className="font-weight-bold">9988667AAA@UPI</span>
                        </div>
                        <span className="text-red" style={{ textDecoration: "underline" }}
                          onClick={() => {
                            this.setState({ canShowUpiVpaSelected: false })
                          }}><small>Remove</small></span>
                      </div>
                    } */}
                    {item?.gateway === 'Phonepe' ?
                      this.state.vpaVerified ?
                        <div className="d-flex flex-column align-items-center justify-content-between w-100" onClick={(e) => item?.identifier === this.state.selectedPaymentMethod && e.stopPropagation()}>
                          <span className="w-100 mb-2 mt-2" style={{ color: "#00D425" }}><small>Verified</small></span>
                          <div className="d-flex justify-content-between align-items-center w-100 p-md-2 p-2 pl-3 mb-2" style={{ background: "#ECECFF", borderRadius: "5px" }}>
                            <div className="d-flex">
                              <img src={this.state.selectedPaymentMethod === "upi"? ic_checkbox_active : ic_checkbox_empty} alt="active" width="18px" className="mr-3" />
                              <div className="d-flex flex-column">
                                <span className="color-blue verified-vpa-name">{this.state.verifiedVpaName}</span>
                                <span className="color-blue font-weight-bold verified-vpa mb-0">{this.state.userEnteredVPA}</span>
                              </div>
                            </div>
                            <span className="text-red clear-upi" style={{ textDecoration: "underline" }} onClick={() => this.clearUpiID()}><small>Clear UPI ID</small></span>
                          </div>
                        </div> :
                        <>
                          <Accordion defaultActiveKey="" onClick={(e) => item?.identifier === this.state.selectedPaymentMethod && e.stopPropagation()}>
                            <Card className="border-0">
                              <Card.Header style={{ background: "#fff", border: 0, width: "fit-content" }} className="pt-md-2 pt-0 pb-md-2 pb-0 pl-md-2 pl-0 pr-md-2 pr-0">
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="gift-header d-flex align-items-center justify-content-start" onClick={(e) => this.upiVpaControl(e)}>
                                  <div className="d-flex align-items-center mr-2 color-blue add-new">
                                    Add New UPI ID
                                  </div>
                                  {this.state.VPAEnabled ?
                                    <img src={close_upi} alt="cross" height="12px" /> :
                                    <img src={add_upi} alt="arrow" height="15px" />
                                  }
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body className="py-2 pl-md-2 pl-0 pr-md-2 pr-0">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <TextField
                                      className="w-100"
                                      outlined
                                      id="outlined-basic"
                                      label="Enter your UPI ID"
                                    // helperText={<HelperText className="text-danger">{this.state.vpaVerificationErrorMessage}</HelperText>}
                                    ><Input
                                        isValid={this.state.vpaVerificationErrorMessage === ""}
                                        value={this.state.userEnteredVPA}
                                        name="vpa"
                                        onChange={(event) => {
                                          this.handleUserVPA(event);
                                        }}
                                      />
                                    </TextField>
                                    {this.state.isVerifyingUpiID ?
                                      <div className="mx-3"><InlineLoader /></div> :
                                      <span className={`upi-verify ${this.state.userEnteredVPA.length < 4 ? 'upi-verify-disabled' : ''}`} onClick={() => this.verifyUserVPA()}>Verify</span>
                                    }
                                  </div>
                                  <small className="text-danger">{this.state.vpaVerificationErrorMessage}</small>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          )
      })
    )
  }

  CartlistData = (props) => {
    const { image_url, name, amount, form } = this.handleCartdataLists()
    const { cart } = this.state;
    return (
      <>
        <div className="p-md-3 p-2 mb-2" style={{ borderRadius: "8px", "boxShadow": "0 0 5px 0 rgba(0,0,0,0.14)", background: "#fff" }}>
          <div className="row align-items-center">
            <div className="col-8 d-flex align-items-center">
              <img style={{ height: "30px", background: "#fff", borderRadius: "8px" }} src={image_url} alt={name} />
              <p style={{ fontSize: "15px", }} className="m-0 ml-2 text-ellipsis" ><strong>{name}</strong></p>
            </div>
            <div className="col-4">
              <div className="align-middle d-flex justify-content-end">
                <p className="mb-0 color-blue">
                  <strong>₹ {parseInt(amount)?.toFixed(2)}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.Cashback()}

        <div className="cart-summary mb-4 mt-md-4 mt-2">
          <h3 className="choose d-flex align-items-center">
            <div className="cart-title-rect mr-3"></div>
            <span>Bill Details</span>
          </h3>
          <div className="card-footer" style={{ border: "none", borderRadius: "8px" }}>
            {
              this.state.currentCartSegment === "bill-pay" &&
              <>
                <div className="list d-flex">
                  <div className="col-6">
                    <p className="mb-0">{form.provider_key1}</p>
                  </div>
                  <div className="col-6 text-right">
                    <p className="mb-0">
                      <strong>{form.provider_value1}</strong>
                    </p>
                  </div>
                </div>

                <div className="list d-flex">
                  <div className="col-6">
                    <p className="mb-0">{form.provider_key2}</p>
                  </div>
                  <div className="col-6 text-right">
                    <p className="mb-0">
                      <strong>{form.provider_value2}</strong>
                    </p>
                  </div>
                </div>
              </>
            }

            {cart.map((item, index) => {
              return (
                <div className="list d-flex" key={index}>
                  <div className="col-6">
                    <p className="mb-0">{item.status_text_translated}</p>
                  </div>
                  <div className="col-6 text-right">
                    <p className="mb-0">
                      <strong>{item.value}</strong>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  PaymentMethods = (props) => {
    return (
      <div className="mb-3 mb-md-2">
        {/* eslint-disable-next-line array-callback-return */}
        {this.state.payment_methods.map((item, index) => {

          if (item.identifier === "upi" && item?.gateway === 'Razorpay')
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-3 cursor-pointer " + (item.is_selected ? 'select-border' : '')} key={item.identifier} onClick={() => this.handlePayment(item.id)}>
                <div className="row align-items-center justify-content-between">
                  <div className="col-1">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                  <div className="col-8 pl-md-0 pl-2">
                    <div className="payment-card-top d-flex flex-row align-items-center mb-1">
                      <h3 className="m-0">{item?.name}</h3>
                      <div className="payment-card-bottom d-flex flex-row align-items-center ml-3">
                        <img src={ic_phonepe} alt="phonepe" />
                        <img src={ic_paytm} alt="paytm" />
                        <img src={ic_airtel} alt="airtel" />
                        <img src={ic_jiomoney} alt="jio" />
                        <img src={ic_more} alt="more" />
                      </div>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0">{item.display_string}</p>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-center">
                    <img src={ic_upi} alt="card" width="60%" />
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "card")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-3 cursor-pointer " + (item.is_selected ? 'select-border' : '')} key={item.identifier} onClick={() => this.handlePayment(item.id)}>
                <div className="row align-items-center justify-content-between">
                  <div className="col-1">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                  <div className="col-8 pl-md-0 pl-2">
                    <div className="payment-card-top d-flex flex-row align-items-center mb-1">
                      <h3 className="m-0">{item?.name}</h3>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0">{item.display_string}</p>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-center">
                    <img src={ic_debit_card} alt="card" width="80%" />
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "netbanking")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-3 cursor-pointer " + (item.is_selected ? 'select-border' : '')} key={item.identifier} onClick={() => this.handlePayment(item.id)}>
                <div className="row align-items-center justify-content-between">
                  <div className="col-1">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                  <div className="col-8 pl-md-0 pl-2">
                    <div className="payment-card-top d-flex flex-row align-items-center mb-1">
                      <h3 className="m-0">{item?.name}</h3>
                      {item?.gateway === 'Phonepe' && this.BankListModal()}
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0">{item.display_string}</p>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-center">
                    <img src={ic_netbanking} alt="netbank" width="80%" />
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "wallet")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-3 cursor-pointer " + (item.is_selected ? 'select-border' : '')} key={item.identifier} onClick={() => this.handlePayment(item.id)}>
                <div className="row align-items-center justify-content-between">
                  <div className="col-1">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                  <div className="col-8 pl-md-0 pl-2">
                    <div className="payment-card-top d-flex flex-row align-items-center mb-1">
                      <h3 className="m-0">{item?.name}</h3>
                      <div className="payment-card-bottom d-flex flex-row align-items-center ml-3">
                        <img src={ic_phonepe} alt="phonepe" />
                        <img src={ic_paytm} alt="paytm" />
                        <img src={ic_airtel} alt="airtel" />
                        <img src={ic_jiomoney} alt="jio" />
                        <img src={ic_more} alt="more" />
                      </div>
                    </div>
                    <div className="payment-card-bottom">
                      <p className="m-0">{item.display_string}</p>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-center">
                    <img src={ic_wallet} alt="wallet" width="60%" />
                  </div>
                </div>
              </div>
            )
        })}
      </div>
    );
  };

  RefundPolicy = (props) => {
    if(this.state.refund_policy && this.state.refund_policy?.text)
    return (
      <div className="refund-policy mt-4">
        <div className=" d-flex flex-row align-items-center">
          <hr className="w-100" />
          <h6 className="px-3 mb-0">REFUND POLICY</h6>
          <hr className="w-100" />
        </div>
        <p className="text-center"
          dangerouslySetInnerHTML={{
            __html: this.state.refund_policy?.text,
          }}
        ></p>
        <div className="d-flex justify-content-center align-items-center cursor-pointer ml-3">
          <a href={this.context?.phonepe_refund_policy} target="_blank" rel="noreferrer">
            <span className="font-weight-bold color-blue">Read our Refund Policy</span>
          </a>
          <img className="ml-2" src={ic_arrow_down} alt="img" width="6px" />
        </div>
      </div>
    )
    else return null;
  }

  ProceedToPay = (props) => {
    return (
      <div className="p-md-3 p-2 mb-4" style={{ borderRadius: "8px", "boxShadow": "0 0 5px 0 rgba(0,0,0,0.14)", background: "#fff" }}>
        <div className="row align-items-center justify-content-center cart-summary">
          {
            this.state.proceedPay &&
            <div className="col-4">
              <div className="mb-0">
                <span>To Pay</span>
                <h3 className="choose m-0 color-blue"><strong>{this.state.proceedPay || "₹ 0"}</strong></h3>
              </div>
            </div>
          }
          <div className="col-8">
            {this.state.load ?
              <div className="text-center">
                <InlineLoader />
              </div> :
              !this.state.load && this.state.proceedPay ? (
                <button className="animated-btn" onClick={this.handleProceedToPay} >
                  <FormattedMessage id="billpay.proceed-to-pay" defaultMessage="PROCEED TO PAY" />
                </button>
              ) : !this.state.load && this.state.gc_wallet_enabled ?
                (
                  <button className="animated-btn" onClick={this.handleProceedToPay} >
                    <FormattedMessage id="pay-via-voucher" defaultMessage="PAY VIA VOUCHER" />
                  </button>
                ) : !this.state.load && this.state.wallet_enabled ?
                  (
                    <button className="animated-btn" onClick={this.handleProceedToPay} >
                      <FormattedMessage id="pay-via-wallet" defaultMessage="PAY VIA WALLET" />
                    </button>
                  ) :
                  (
                    <button className="animated-btn-disabled" disabled >
                      <FormattedMessage id="billpay.proceed-to-pay" defaultMessage="PROCEED TO PAY" />
                    </button>
                  )
            }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    voucher: state.voucher.voucher,
    billpayData: state.order.provider,
    denomination: state.voucher.denomination,
    quantity: state.voucher.quantity,
    cashback: state.voucher.cashback,
    giftDetails: state.voucher.giftDetails,
    billPayForm: state.order.billPayForm,
    billpayAPIVersion: state.order.billpayAPIVersion
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CartSummary)));
